import { LeadSection, Button, Icon, Post, Section, PostContentsTitle, Title, PostContentsContainer, Media, CardGrid, Card, SizeWrapper } from "@lleed-partners/factor";
import { ScanAlt32, Archive32, Delivery32 } from "@carbon/icons-react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from 'react';
export default {
  LeadSection,
  Button,
  Icon,
  Post,
  Section,
  PostContentsTitle,
  Title,
  PostContentsContainer,
  Media,
  CardGrid,
  Card,
  SizeWrapper,
  ScanAlt32,
  Archive32,
  Delivery32,
  GatsbyImage,
  getImage,
  React
};