import styled from 'styled-components'

export const GroupContainer = styled.span`
  display: flex;
`

export interface IGroupElementProps {
  /** Allow the element to take up the full width available */
  allowEnlarge?: boolean
}

export const GroupElement = styled.span<IGroupElementProps>`
  display: inline-block;
  ${({ allowEnlarge = false }) => `
    ${allowEnlarge ? 'flex: 1 0 auto;' : ''}
  `}
`
