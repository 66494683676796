import { css, keyframes } from 'styled-components'

import { IGenericElementProps } from '~typings/props'
import {
  skeletonBackgroundColor,
  skeletonForegroundColor
} from '~variables/colors'
import { focusRingSize } from '~variables/sizes'

export interface ISkeletonProps extends IGenericElementProps {
  /** Whether or not to display the skeleton state */
  skeleton?: boolean
}

export const skeletonKeyframes = keyframes`
  0% {
    right: 100%;
    left: -${focusRingSize}px;
    opacity: 0.1;
  }
  50% {
    right: -${focusRingSize}px;
    left: -${focusRingSize}px;
    opacity: 0.8;
  }
  100% {
    right: -${focusRingSize}px;
    left: 100%;
    opacity: 0.6;
  }
`

/**
 * Skeleton mixin
 */
export const skeleton = css`
  position: relative;

  &:after,
  &:before {
    position: absolute;
    top: -${focusRingSize}px;
    right: -${focusRingSize}px;
    bottom: -${focusRingSize}px;
    left: -${focusRingSize}px;
    display: ${(props: ISkeletonProps) => (props.skeleton ? 'block' : 'none')};
    content: '';
    pointer-events: none;
  }

  &:after {
    z-index: 9;
    background-color: ${skeletonBackgroundColor};
  }

  &:before {
    z-index: 10;
    animation: ${skeletonKeyframes} 1.5s ease-in-out infinite;
    background-color: ${skeletonForegroundColor};
    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }
`
