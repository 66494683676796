import { createContext } from 'react'
import styled from 'styled-components'

import {
  coloredBackgroundComponent,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { ISizedComponentProps, sizedComponent } from '~fragments/size'
import { IChildrenElementProps } from '~typings/props'
import { intents, TIntents } from '~variables/colors'
import { TSizes } from '~variables/sizes'

interface ILogoGridContext {
  size: TSizes
  intent: TIntents
}

const LogoGridContext = createContext<ILogoGridContext>({
  intent: 'white',
  size: 'normal'
})

interface ILogoGridLogoRendererProps
  extends IColoredBackgroundComponentProps,
    ISizedComponentProps {}

type ILogoGridLogoProps = IChildrenElementProps

const LogoGridLogoRenderer = styled.div<ILogoGridLogoRendererProps>`
  ${sizedComponent({
    baseline: 'layout',
    padding: 2
  })}

  ${coloredBackgroundComponent({
    interactive: false
  })}

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;

  & > span {
    display: inline-block;
    text-align: center;
    flex: 0 0 auto;

    & > * {
      width: 70%;
      max-height: 4em;
      max-width: 70%;
    }
  }
`

export const LogoGridLogo = ({ children, ...props }: ILogoGridLogoProps) => {
  return (
    <LogoGridContext.Consumer>
      {(context) => (
        <LogoGridLogoRenderer {...context} {...props}>
          <span>{children}</span>
        </LogoGridLogoRenderer>
      )}
    </LogoGridContext.Consumer>
  )
}

export interface ILogoGridProps
  extends IChildrenElementProps,
    ISizedComponentProps,
    IColoredBackgroundComponentProps {
  /** Display a border between logos */
  bordered?: boolean
  /** Puts logo in greyscale */
  greyscale?: boolean
}

const LogoGridRenderer = styled.div<ILogoGridProps>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(18rem, 48%), 1fr));
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  grid-auto-rows: 1fr;

  ${coloredBackgroundComponent({
    interactive: false
  })}

  ${({ bordered = false, intent = 'white' }) => {
    return bordered
      ? `
    overflow: hidden;

    & > *:not(:last-child) {
      outline: 1px solid ${intents[intent].backgroundColor.hover};
    }
  `
      : ''
  }}

  ${({ greyscale = false }) => {
    return greyscale
      ? `
      span {
        filter: brightness(0) invert(0.5);
      }
  `
      : ''
  }}
`

export const LogoGrid = ({
  children,
  intent = 'white',
  size = 'normal',
  ...props
}: ILogoGridProps) => {
  return (
    <LogoGridContext.Provider
      value={{
        intent,
        size
      }}
    >
      <LogoGridRenderer intent={intent} size={size} {...props}>
        {children}
      </LogoGridRenderer>
    </LogoGridContext.Provider>
  )
}
