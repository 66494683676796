import { useContext } from 'react'
import styled from 'styled-components'

import { NavContext } from '~components/UI/Nav/shared/Context'
import { intents, TIntents } from '~variables/colors'
import { navSizes } from '~variables/sizes'

export const NavSeparatorVerticalRenderer = styled.div<{
  intent: TIntents
}>`
  position: relative;
  width: ${navSizes.separatorSize}px;
  margin-right: -${navSizes.separatorSize}px;
  &:after {
    position: absolute;
    top: ${(1 - navSizes.horizontalSeparatorRatio) * 50}%;
    right: 0;
    bottom: ${(1 - navSizes.horizontalSeparatorRatio) * 50}%;
    left: 0;
    background-color: ${({ intent }) => intents[intent].backgroundColor.hover};
    content: '';
    z-index: 1;
  }
`

export const NavSeparatorVertical = () => {
  const { intent } = useContext(NavContext)

  return <NavSeparatorVerticalRenderer intent={intent} />
}
