import { createContext } from 'react'

import { TIntents } from '~variables/colors'
import { TSizes } from '~variables/sizes'

export interface INavContext {
  /** Nav intent */
  intent: TIntents
  /** Nav sizing */
  size: TSizes
}

export const defaultNavContext: INavContext = {
  intent: 'dark',
  size: 'large'
}

export const NavContext = createContext<INavContext>(defaultNavContext)
