import {
  ArrowRight32,
  SettingsAdjust32,
  WarningAlt32
} from '@carbon/icons-react'
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'
import fetch from 'isomorphic-fetch'
import Cookie from 'js-cookie'
import {
  ChangeEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import styled from 'styled-components'

import { CardList } from '~components/Patterns'
import { Button } from '~components/UI/Button'
import { Card } from '~components/UI/Card'
import { FormCheckbox } from '~components/UI/Form'
import { Icon } from '~components/UI/Icon'
import { Modal } from '~components/UI/Modal'
import { IChildrenElementProps } from '~typings/props'

import {
  defaultTrackingContext,
  ITrackingEvent,
  ITrackingProviderProps,
  TrackingContext
} from './track'

export interface ISegmentTrackingProviderProps
  extends ITrackingProviderProps,
    IChildrenElementProps {
  writeKey: string
  translations?: ITranslations
}

interface IDestination {
  category: string
  description: string
  name: string
  id: string
  website: string
  creationName: string
}

const getDestinations = async ({
  writeKey
}: {
  writeKey: string
}): Promise<IDestination[]> => {
  const res = await fetch(
    `https://cdn.segment.com/v1/projects/${writeKey}/integrations`
  )

  if (!res.ok) {
    throw new Error(
      `Failed to fetch integrations for write key ${writeKey}: HTTP ${res.status} ${res.statusText}`
    )
  }

  let destinations = await res.json()

  destinations = destinations.map((destination: IDestination) => {
    return {
      ...destination,
      id: destination.creationName
    }
  })

  return destinations
}

interface IConsents {
  destinations?: {
    [key: string]: boolean
  }
}

const COOKIE_NAME = 'factor-segment-consents'

const getConsents = (): IConsents => {
  const storedValue = Cookie.get(COOKIE_NAME)

  return storedValue ? JSON.parse(storedValue) : {}
}

const setConsents = ({ consents }: { consents: IConsents }) => {
  Cookie.set(COOKIE_NAME, JSON.stringify(consents), { expires: 31 })
}

const ConsentWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  max-height: 100%;
`

const ConsentContext = createContext({
  showConsent: () => {
    // eslint-disable-next-line no-console
    console.warn('No context attached')
  }
})

export interface ITranslations {
  cookies?: string
  disclaimer?: string
  accept?: string
  customize?: string
  saveChoices?: string
  cancel?: string
  enable?: string
  integrations?: {
    [integrationId: string]: {
      name?: string
      description?: string
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const exctractByProps = (obj: Record<string, any>, props: string[]) => {
  return props.reduce(
    (acc, prop) =>
      typeof acc === 'object' && prop in acc ? acc[prop] : undefined,
    obj
  )
}

const ConsentManager = ({
  open = false,
  onSubmit,
  destinations,
  translations = {}
}: {
  open: boolean
  destinations: IDestination[]
  onSubmit: (consents: IConsents) => void
  // eslint-disable-next-line react/require-default-props
  translations?: ITranslations
}) => {
  const [showOptions, setShowOptions] = useState(false)
  const [preferences, setPreferences] = useState<IConsents>({
    destinations: {}
  })

  const t = (property: string, fallback: string) => {
    const path = property.split('.')
    const translation = exctractByProps(translations, path)

    if (translation) return translation
    return fallback
  }

  useEffect(() => {
    const defaultPreferences: IConsents = {
      destinations: {}
    }

    destinations.forEach(({ id }) => {
      if (defaultPreferences.destinations)
        defaultPreferences.destinations[id] = false
    })

    setPreferences({
      ...preferences,
      destinations: {
        ...defaultPreferences.destinations,
        ...preferences.destinations
      }
    })
  }, [destinations])

  const submit = (acceptAll = false): (() => void) => {
    return () => {
      const targetPreferences = { ...preferences }

      if (!targetPreferences || !targetPreferences.destinations) return

      if (acceptAll) {
        Object.keys(targetPreferences.destinations).forEach((id) => {
          if (targetPreferences.destinations)
            targetPreferences.destinations[id] = true
        })
      }

      onSubmit(targetPreferences)
    }
  }

  return (
    <Modal open={open} standalone>
      <ConsentWrapper>
        {showOptions ? (
          <div>
            <CardList bordered>
              {destinations.map((destination) => {
                return (
                  <Card
                    size="large"
                    intent="white"
                    title={t(
                      `integrations.${destination.id}.name`,
                      destination.name
                    )}
                    cta={
                      <FormCheckbox
                        defaultChecked={
                          preferences &&
                          preferences.destinations &&
                          preferences.destinations[destination.id]
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setPreferences({
                            ...preferences,
                            destinations: {
                              ...preferences.destinations,
                              [destination.id]: e.currentTarget.checked
                            }
                          })
                        }}
                      >
                        {t('enable', 'Enable')}{' '}
                        {t(
                          `integrations.${destination.id}.name`,
                          destination.name
                        )}
                      </FormCheckbox>
                    }
                  >
                    {t(
                      `integrations.${destination.id}.description`,
                      destination.description
                    )}
                  </Card>
                )
              })}
            </CardList>
            <Card
              size="large"
              intent="white"
              cta={
                <>
                  <Button
                    intent="primary"
                    rightIcon={<Icon icon={<ArrowRight32 />} />}
                    onClick={submit()}
                  >
                    {t('saveChoices', 'Save choices')}
                  </Button>
                  <Button
                    intent="ghost"
                    onClick={() => {
                      setShowOptions(false)
                    }}
                  >
                    {t('cancel', 'Cancel')}
                  </Button>
                </>
              }
            />
          </div>
        ) : (
          <Card
            size="large"
            intent="white"
            title={t('cookies', 'Cookies')}
            icon={<Icon icon={<WarningAlt32 />} />}
            cta={
              <>
                <Button
                  intent="primary"
                  rightIcon={<Icon icon={<ArrowRight32 />} />}
                  onClick={submit(true)}
                >
                  {t('accept', 'Accept fair cookies')}
                </Button>
                <Button
                  intent="ghost"
                  rightIcon={<Icon icon={<SettingsAdjust32 />} />}
                  onClick={() => {
                    setShowOptions(true)
                  }}
                >
                  {t('customize', 'Customize')}
                </Button>
              </>
            }
          >
            {t(
              'disclaimer',
              'We use anonymous cookies to improve your experience on our website. We do not use your data for advertising or marketing purposes.'
            )}
          </Card>
        )}
      </ConsentWrapper>
    </Modal>
  )
}

export const useConsent = () => {
  return useContext(ConsentContext)
}

export const SegmentTrackingProvider = ({
  writeKey,
  children,
  translations,
  ...props
}: ISegmentTrackingProviderProps) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)
  const [showConsents, setShowConsents] = useState<boolean>(false)
  const [destinations, setDestinations] = useState<IDestination[]>([])
  const [consented, setConsented] = useState<boolean>(false)

  const onSubmit = (consents: IConsents) => {
    let hadConsented = false
    if (consented) hadConsented = true
    setConsents({ consents })
    setShowConsents(false)
    setConsented(true)

    if (hadConsented) window.location.reload()
  }

  useEffect(() => {
    const load = async () => {
      if (consented) {
        const consents = getConsents()

        const [response] = await AnalyticsBrowser.load(
          { writeKey },
          consents.destinations
            ? {
                integrations: consents.destinations
              }
            : undefined
        )

        setAnalytics(response)
      }
    }

    load()
  }, [consented])

  useEffect(() => {
    const loadAnalytics = async () => {
      const destinations = await getDestinations({ writeKey })

      const { destinations: consentDestinations } = getConsents()

      setDestinations(destinations)

      let askForConsent = false

      if (consentDestinations) {
        destinations.forEach((destination) => {
          if (Object.keys(consentDestinations).indexOf(destination.id) < 0) {
            askForConsent = true
          }
        })
      } else {
        askForConsent = true
      }

      if (askForConsent) {
        setShowConsents(true)
      } else {
        setConsented(true)
      }
    }
    loadAnalytics()
  }, [writeKey])

  const track = useCallback(
    (event: ITrackingEvent) => {
      if (event.type === 'page') {
        analytics?.page()
      } else {
        analytics?.track(event.name, event.properties)
      }
    },
    [analytics]
  )

  return (
    <TrackingContext.Provider
      value={{
        ...defaultTrackingContext,
        ...props,
        track
      }}
    >
      <ConsentContext.Provider
        value={{
          showConsent: () => {
            setShowConsents(true)
          }
        }}
      >
        {children}
        <ConsentManager
          onSubmit={onSubmit}
          open={showConsents}
          destinations={destinations}
          translations={translations}
        />
      </ConsentContext.Provider>
    </TrackingContext.Provider>
  )
}
