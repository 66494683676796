import { css } from 'styled-components'

import { GroupContainer, GroupElement } from '~fragments/group'
import { IGenericElementProps } from '~typings/props'
import {
  focusRingSize,
  ISize,
  lineHeightRatio,
  referenceFontFactor,
  sizes,
  TSizes,
  unit
} from '~variables/sizes'

/**
 * Common sizing components
 */

export const defaultComponent = css`
  box-sizing: border-box;
  text-align: left;
`

export const borderComponent = css`
  ${defaultComponent}

  border: ${focusRingSize}px solid transparent;
`

/**
 * Box component
 */

export interface ISizedBoxComponentProps extends IGenericElementProps {
  /** Size of the component */
  size?: TSizes
  /** Display */
  display?: 'block' | 'inline'
}

/**
 * Sized box component
 * @param providedSize Provide a specific size to use for size-locked
 * elements
 */
export const sizedBoxComponent = (providedSize?: ISize) => css`
  ${borderComponent}

  ${({ display = 'inline' }: ISizedBoxComponentProps) =>
    display === 'block' ? 'display: block;' : 'display: inline-block;'}

  ${({ size = 'normal' }: ISizedBoxComponentProps) => `
    & > ${GroupContainer} > ${GroupElement} {
      font-size: ${(providedSize || sizes[size]).fontSizeFactor * unit}rem;
      line-height: ${
        (providedSize || sizes[size]).fontSizeFactor * lineHeightRatio * unit
      }rem;
      padding: ${
        (((providedSize || sizes[size]).baseHeightFactor -
          (providedSize || sizes[size]).fontSizeFactor * lineHeightRatio) /
          2) *
        unit
      }rem;

      & + ${GroupElement} {
        padding-left: 0;
      }
    }

    ${GroupContainer} {
      margin: -${focusRingSize}px;
    }
  `}
`

type TSideSizingOption = number | boolean | undefined

type TSideSizingOptions =
  | {
      x?: TSideSizingOption
      y?: TSideSizingOption
      top?: TSideSizingOption
      right?: TSideSizingOption
      bottom?: TSideSizingOption
      left?: TSideSizingOption
    }
  | boolean
  | number

interface ISizedComponentOptions {
  /** Set padding for the element */
  padding?: TSideSizingOptions
  /** Set margin for the element */
  margin?: TSideSizingOptions
  /** Adds a font scaling factor for the element */
  fontScaling?: number | null
  /** Adds a border around the element */
  border?: boolean
  /** Baseline (font-size or unit) */
  baseline?: 'font' | 'layout'
  /** Skips adding padding declarations */
  skipPadding?: boolean
  /** Skips adding margin declarations */
  skipMargin?: boolean
}

export interface ISizedComponentProps {
  /** Size */
  size?: TSizes
}

const withSpecificitySize = (...args: TSideSizingOption[]): number => {
  const value = args.find((arg) => typeof arg !== 'undefined')

  if (typeof value === 'boolean') return value ? 1 : 0
  if (typeof value === 'undefined') return 0
  return value
}

const generateSizeAttributes = (
  property: string,
  config: TSideSizingOptions,
  baseline: 'font' | 'layout'
) => {
  const c = {
    top: withSpecificitySize(
      typeof config === 'object' ? config.top : undefined,
      typeof config === 'object' ? config.y : undefined,
      typeof config === 'boolean' || typeof config === 'number'
        ? config
        : undefined,
      undefined
    ),
    right: withSpecificitySize(
      typeof config === 'object' ? config.right : undefined,
      typeof config === 'object' ? config.x : undefined,
      typeof config === 'boolean' || typeof config === 'number'
        ? config
        : undefined,
      undefined
    ),
    bottom: withSpecificitySize(
      typeof config === 'object' ? config.bottom : undefined,
      typeof config === 'object' ? config.y : undefined,
      typeof config === 'boolean' || typeof config === 'number'
        ? config
        : undefined,
      undefined
    ),
    left: withSpecificitySize(
      typeof config === 'object' ? config.left : undefined,
      typeof config === 'object' ? config.x : undefined,
      typeof config === 'boolean' || typeof config === 'number'
        ? config
        : undefined,
      undefined
    )
  }

  return css<ISizedComponentProps>`
    ${({ size = 'normal' }) => {
      const sizeFactor =
        baseline === 'font'
          ? ((sizes[size].baseHeightFactor -
              sizes[size].fontSizeFactor * lineHeightRatio) /
              2) *
            unit
          : sizes[size].paddingFactor * unit

      return `${property}: ${c.top * sizeFactor}rem ${
        c.right * sizeFactor
      }rem ${c.bottom * sizeFactor}rem ${c.left * sizeFactor}rem;`
    }}
  `
}

// TODO: font scaling?

export const sizedComponent = ({
  padding = true,
  margin = false,
  border = false,
  baseline = 'layout',
  fontScaling = null,
  skipPadding = false,
  skipMargin = false
}: ISizedComponentOptions) => {
  return css<ISizedComponentProps>`
    line-height: ${lineHeightRatio}em;

    ${fontScaling
      ? `font-size: ${fontScaling * referenceFontFactor * unit}rem;`
      : ''}

    ${border
      ? css`
          ${borderComponent}
        `
      : defaultComponent}

    ${skipPadding ? '' : generateSizeAttributes('padding', padding, baseline)}
    ${skipMargin ? '' : generateSizeAttributes('margin', margin, baseline)}
  `
}
