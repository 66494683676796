/**
 * Base sizing elements
 */

export const baseUnit = 8
export const defaultFontSize = 2 * baseUnit
export const unit = baseUnit / defaultFontSize

export const referenceFontFactor = defaultFontSize / baseUnit

export const lineHeightRatio = 1.25

export const focusRingSize = 2

/**
 * UI Sizes
 */

export interface ISize {
  fontSizeFactor: number
  baseHeightFactor: number
  paddingFactor: number
}

export type TSizes = 'small' | 'normal' | 'large' | 'xLarge'

export const small: ISize = {
  fontSizeFactor: 2,
  baseHeightFactor: 4,
  paddingFactor: 1
}

export const normal: ISize = {
  fontSizeFactor: 2,
  baseHeightFactor: 6,
  paddingFactor: 2
}

export const large: ISize = {
  fontSizeFactor: 2,
  baseHeightFactor: 8,
  paddingFactor: 3
}

export const xLarge: ISize = {
  fontSizeFactor: 3,
  baseHeightFactor: 10,
  paddingFactor: 4
}

export const sizes: {
  [key in TSizes]: ISize
} = {
  small,
  normal,
  large,
  xLarge
}

export interface INavSizes {
  separatorSize: number
  main: ISize & {
    logoHeight: number
  }
  sub: ISize
  horizontalSeparatorRatio: number
  breakpoint: number
}

export const mobileBreakpoint = 100

export const navSizes: INavSizes = {
  main: {
    ...large,
    logoHeight: 1.75
  },
  sub: {
    ...normal
  },
  separatorSize: 1,
  horizontalSeparatorRatio: 0.5,
  breakpoint: mobileBreakpoint
}

export default sizes
