module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/titouanlaunay/Documents/LleedAndPartners/repos/marketing-website/src/layouts/global.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[],"placeholder":"blurred"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53aa06cf17e4239d0dba6ffd09854e02"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"rehypePlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-vscode","options":{"theme":"Quiet Light"}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/titouanlaunay/Documents/LleedAndPartners/repos/marketing-website"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["fr-ca","en-ca","en-us","en-mx","en-gb","en-ie","en-sg","en-au","en-nz","fr-fr","fr-ch","fr-lu","fr-be","en","fr"],"defaultLanguage":"en","siteUrl":"https://lleedpartners.com","generateDefaultLanguagePage":true,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"nonExplicitSupportedLngs":true},"redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lleed & Partners","short_name":"L&P","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"standalone","icon":"src/images/common/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1f30bba34957276d0eac18767f31a463"},
    }]
