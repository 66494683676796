import {
  cloneElement,
  KeyboardEvent,
  PointerEvent,
  ReactElement,
  useCallback,
  useRef,
  useState
} from 'react'
import styled from 'styled-components'

import { NavContext } from '~components/UI/Nav/shared/Context'
import { ILinkProps, Link } from '~components/UI/Nav/shared/Link'
import { onClickARIA } from '~fragments/aria'
import { useOnClickOutside, useOnLinkClick } from '~fragments/hooks'

export * from '~components/UI/Nav/Main/Link/Dropdown'

export interface INavMainLinkProps extends ILinkProps {
  /** Dropdown */
  dropdown?: ReactElement
  /** Identifier */
  id: string
}

const NavMainLinkLiRenderer = styled.li`
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
`

export const NavMainLink = ({
  children,
  dropdown,
  id,
  ...props
}: INavMainLinkProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

  const container = useRef<HTMLLIElement>(null)

  useOnLinkClick(container, () => {
    setIsDropdownOpen(false)
  })

  useOnClickOutside(container, () => {
    setIsDropdownOpen(false)
  })

  const clickHandler = useCallback((e?: KeyboardEvent | PointerEvent) => {
    if (e) {
      e.preventDefault()
    }

    setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen)
  }, [])

  const linkProps = dropdown
    ? {
        as: 'span',
        role: 'button',
        tabIndex: 0,
        onMouseDown: (e: PointerEvent) => {
          e.preventDefault()
        },
        onClick: clickHandler,
        onKeyDown: onClickARIA(clickHandler),
        'aria-expanded': isDropdownOpen,
        'aria-controls': id,
        active: isDropdownOpen
      }
    : undefined

  return (
    <NavContext.Consumer>
      {(navContext) => (
        <NavContext.Provider
          value={{ ...navContext, intent: props.intent || navContext.intent }}
        >
          <NavMainLinkLiRenderer ref={container}>
            <Link {...linkProps} {...props}>
              {children}
            </Link>
            {dropdown
              ? cloneElement(dropdown, {
                  id,
                  open: isDropdownOpen
                })
              : null}
          </NavMainLinkLiRenderer>
        </NavContext.Provider>
      )}
    </NavContext.Consumer>
  )
}
