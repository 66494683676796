import { css } from 'styled-components'

import { transition } from '~fragments/transition'
import { IGenericElementProps } from '~typings/props'
import {
  contentGradientPoints,
  IIntent,
  intents,
  TIntents
} from '~variables/colors'

/**
 * Background-colored component
 */
export interface IColoredBackgroundComponentProps extends IGenericElementProps {
  /** Intent of the component */
  intent?: TIntents
  /** Disabled */
  disabled?: boolean
}

/**
 * Returns a CSS mixin for background colored components
 * @param interactive Whether or not the component supports interactivity
 * with the user pointing device.
 */
export const coloredBackgroundComponent = ({
  interactive = false,
  defaultTone = 'default',
  intent: providedIntent
}: {
  interactive?: boolean
  intent?: IIntent
  defaultTone?: 'hover' | 'default'
}) => css`
  ${({ intent = 'primary' }: IColoredBackgroundComponentProps) => `
    color: ${(providedIntent || intents[intent]).textColor.default};
    background-color: ${
      (providedIntent || intents[intent]).backgroundColor[defaultTone]
    };
    border-color: ${
      (providedIntent || intents[intent]).backgroundColor[defaultTone]
    };
  `}

  ${interactive &&
  (({ intent = 'primary', disabled }: IColoredBackgroundComponentProps) => `
    transition: all ${transition};
    ${
      disabled === true
        ? ''
        : `
      ${!disabled ? '&:hover' : '&:not(:disabled):hover'} {
        background-color: ${
          (providedIntent || intents[intent]).backgroundColor.hover
        };
        ${
          (providedIntent || intents[intent]).noBorderColor
            ? ''
            : `border-color: ${
                (providedIntent || intents[intent]).backgroundColor.hover
              };`
        }
      }
      ${!disabled ? '&:active' : '&:not(:disabled):active'} {
        background-color: ${
          (providedIntent || intents[intent]).backgroundColor.focus
        };
        ${
          (providedIntent || intents[intent]).noBorderColor
            ? ''
            : `border-color: ${
                (providedIntent || intents[intent]).backgroundColor.focus
              };`
        }
      }
    `
    }


    ${disabled ? '&' : '&:disabled'} {
      background-color: ${
        (providedIntent || intents[intent]).backgroundColor.disabled ||
        'transparent'
      };
      border-color: ${
        (providedIntent || intents[intent]).backgroundColor.disabled ||
        'transparent'
      };
      ${
        (providedIntent || intents[intent]).textColor.disabled &&
        `
          color: ${(providedIntent || intents[intent]).textColor.disabled}
        `
      };
    }
  `)}
`

export const contentGradient = contentGradientPoints
  .map((color, index) => {
    return `${color} ${(index / (contentGradientPoints.length - 1)) * 100}%`
  })
  .join(', ')
