import { PostContentsContainer, CardGrid, CardList, Card, PostContentsTitle, Title, Icon, Button } from "@lleed-partners/factor";
import { Events32, Loop32, Workspace32, Code32, TextLinkAnalysis32, Replicate32, ArrowRight32 } from "@carbon/icons-react";
import * as React from 'react';
export default {
  PostContentsContainer,
  CardGrid,
  CardList,
  Card,
  PostContentsTitle,
  Title,
  Icon,
  Button,
  Events32,
  Loop32,
  Workspace32,
  Code32,
  TextLinkAnalysis32,
  Replicate32,
  ArrowRight32,
  React
};