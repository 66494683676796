import { PostContentsContainer, Card, CardGrid, CardList, Icon, PostContentsTitle, Title, LogoGrid, LogoGridLogo } from "@lleed-partners/factor";
import { Corn32, Money32, Collaborate32 } from "@carbon/icons-react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from 'react';
export default {
  PostContentsContainer,
  Card,
  CardGrid,
  CardList,
  Icon,
  PostContentsTitle,
  Title,
  LogoGrid,
  LogoGridLogo,
  Corn32,
  Money32,
  Collaborate32,
  GatsbyImage,
  getImage,
  React
};