import { css } from 'styled-components'

import { blue } from '~variables/colors'
import { focusRingSize } from '~variables/sizes'

export const noUserSelect = css`
  user-select: none;
`

export const focusRingVisible = css`
  border: ${focusRingSize}px solid ${blue.shades[0]} !important;
  box-shadow: inset 0 0 0 1px white;
  outline: none;
`

export const focusRing = css`
  &:focus-visible {
    ${focusRingVisible}
  }

  &:focus {
    outline: none;
  }
`

export const outlineFocusRing = css`
  &:focus-visible {
    outline: ${focusRingSize}px solid ${blue.shades[0]} !important;
    box-shadow: inset 0 0 0 1px white;
  }
`

export const pointerInteractive = css`
  ${focusRing}
  cursor: pointer;
  text-decoration: none;
`
