import { ReactElement } from 'react'
import styled from 'styled-components'

import { Logo } from '~components/Brand/Logo'
import { useTracking } from '~components/Meta/Tracking'
import {
  coloredBackgroundComponent,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import {
  focusRing,
  noUserSelect,
  pointerInteractive
} from '~fragments/interaction'
import { sizedComponent } from '~fragments/size'
import {
  IChildrenElementProps,
  IGenericElementProps,
  ITrackableElement,
  IWithAsElementProps,
  TIconType
} from '~typings/props'

import { FooterContext } from './context'

export * from './LanguageSwitcher'

type IFooterRendererProps = IColoredBackgroundComponentProps

const FooterRenderer = styled.footer<IFooterRendererProps>`
  ${coloredBackgroundComponent({ interactive: false })}
  ${sizedComponent({
    padding: {
      top: 4,
      bottom: 2,
      x: 2
    },
    margin: 0
  })}
`

const FooterLogoRenderer = styled.div`
  max-width: 10rem;
  opacity: 0.6;
`

const FooterLinks = styled.ul`
  list-style: none;
  columns: 20rem auto;
  ${sizedComponent({
    padding: {
      x: 0,
      y: 0
    },
    margin: {
      x: 0,
      top: 2,
      bottom: 0
    }
  })}
  border-top: 1px solid rgba(255, 255, 255, .1);
`

const FooterLinksCategoryRenderer = styled.li`
  ${sizedComponent({
    padding: {
      x: 0,
      y: 1
    },
    margin: 0
  })}
  break-inside: avoid-column;
`
export interface IFooterLinksCategoryProps
  extends IChildrenElementProps,
    IFooterLinkProps {
  /** Title of the category */
  title?: string
}

interface IFooterLinkRendererProps {
  /** Render as category title */
  asTitle?: boolean
}

const FooterLinkWrapper = styled.li``

const FooterLinkRenderer = styled.span<IFooterLinkRendererProps>`
  background-color: transparent;
  border-radius: none;

  ${pointerInteractive}
  ${noUserSelect}
  ${sizedComponent({
    padding: {
      x: 0,
      y: 0.25
    },
    margin: 0,
    border: true
  })}

  max-width: 15em;
  text-decoration: none;
  color: inherit;
  opacity: ${({ asTitle = false }) => (asTitle ? 1 : 0.6)};
  display: block;
  ${focusRing}
`

const FooterLinksCategoryLinks = styled.ul`
  list-style: none;
  padding: 0;
`

export const FooterLinksCategory = ({
  children,
  title,
  ...props
}: IFooterLinksCategoryProps) => {
  return (
    <FooterLinksCategoryRenderer>
      <FooterLinkRenderer asTitle {...props}>
        {title}
      </FooterLinkRenderer>
      <FooterLinksCategoryLinks>{children}</FooterLinksCategoryLinks>
    </FooterLinksCategoryRenderer>
  )
}

const FooterLinkIconRenderer = styled.span`
  transform: scale(0.65);
  position: relative;
  display: inline-block;
  margin-left: 0.25em;
`

export interface IFooterLinkProps
  extends IChildrenElementProps,
    IWithAsElementProps<Record<string, unknown>>,
    ITrackableElement,
    IGenericElementProps {
  /** Optional icon */
  icon?: TIconType
}

export const FooterLink = ({
  children,
  icon,
  trackId,
  ...props
}: IFooterLinkProps) => {
  const trackingClickHandler = useTracking({
    type: 'event',
    name: 'click',
    properties: {
      component: 'factor.footer.link',
      trackId
    }
  })

  const clickHandler = () => {
    trackingClickHandler()

    if (props && props.onClick) props.onClick()
  }

  return (
    <FooterLinkWrapper>
      <FooterLinkRenderer {...props} onClick={clickHandler}>
        {children}
        {icon && <FooterLinkIconRenderer>{icon}</FooterLinkIconRenderer>}
      </FooterLinkRenderer>
    </FooterLinkWrapper>
  )
}

export interface IFooterProps extends IFooterRendererProps {
  languageSwitcher?: ReactElement
}

export const Footer = ({
  intent = 'secondary',
  children,
  languageSwitcher,
  ...props
}: IFooterProps) => {
  return (
    <FooterRenderer intent={intent} {...props}>
      <FooterContext.Provider value={{ intent }}>
        <FooterLogoRenderer>
          <Logo />
        </FooterLogoRenderer>
        <FooterLinks>{children}</FooterLinks>
        {languageSwitcher}
      </FooterContext.Provider>
    </FooterRenderer>
  )
}
