import { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

import { IColoredBackgroundComponentProps } from '~fragments/color'
import { h4, p } from '~fragments/common/typography'
import { sizedComponent } from '~fragments/size'
import { IChildrenElementProps } from '~typings/props'

interface IInline {
  /** Inline the title with the component */
  inline?: boolean
}

export interface ITitleProps extends IChildrenElementProps, IInline {
  /** Title of the section */
  title: string
  /** Subtitle of the section */
  subtitle?: string
  /** Title cta */
  cta?: ReactNode
}

const TitleRenderer = styled.div<IInline>`
  ${({ inline }) =>
    sizedComponent({
      margin: 0,
      padding: { top: inline ? 0 : 1 }
    })}
`

const TitleTitleRenderer = styled.h2<IInline>`
  ${({ inline }) => (inline ? h4 : '')}
  max-width: 15em;
  font-weight: bold;
  ${sizedComponent({ margin: 0, padding: 0 })}
`

const TitleSubTitleRenderer = styled.span`
  display: block;
  font-weight: 400;
`

const TitleContentsRenderer = styled.div<IInline>`
  ${({ inline }) =>
    sizedComponent({ margin: 0, padding: { top: inline ? 1 : 2, bottom: 1 } })}
  max-width: 20em;
  ${({ inline }) => (inline ? p : 'font-size: 1.25em')};
`

const TitleCTARenderer = styled.div`
  ${sizedComponent({ margin: 0, padding: { top: 2 } })}
`

export interface ISectionWrapperProps extends IColoredBackgroundComponentProps {
  /** Title */
  title?: ReactElement
  /** Section contents */
  children?: ReactNode
  /** Display Title and children inline */
  inline?: boolean
  /** Background media (`<Media/>` element) */
  backgroundMedia?: ReactNode
}

export const Title = ({
  title,
  subtitle,
  cta,
  children,
  inline,
  ...props
}: ITitleProps) => {
  return (
    <TitleRenderer inline={inline} {...props}>
      <TitleTitleRenderer inline={inline}>
        {title}
        {subtitle && <TitleSubTitleRenderer> {subtitle}</TitleSubTitleRenderer>}
      </TitleTitleRenderer>
      {children && (
        <TitleContentsRenderer inline={inline}>
          {children}
        </TitleContentsRenderer>
      )}
      {cta && <TitleCTARenderer>{cta}</TitleCTARenderer>}
    </TitleRenderer>
  )
}
