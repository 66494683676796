import { LogoLinkedin32, UserAdmin32 } from '@carbon/icons-react'
import { cloneElement, ReactElement } from 'react'
import flattenChildren from 'react-keyed-flatten-children'
import styled from 'styled-components'

import { Icon } from '~components/UI/Icon'
import {
  coloredBackgroundComponent,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { ISizedComponentProps, sizedComponent } from '~fragments/size'
import { IChildrenElementProps } from '~typings/props'

import { intents } from '../../../variables/colors'

interface IPostRendererProps
  extends IChildrenElementProps,
    IColoredBackgroundComponentProps {}

const grid2MediaQuery = '@media (min-width: 60rem) and (max-width: 70rem)'
const grid3MediaQuery = '@media (min-width: 70rem)'

const PostRenderer = styled.div<IPostRendererProps>`
  ${coloredBackgroundComponent({
    interactive: false
  })}

  ${sizedComponent({
    baseline: 'layout',
    margin: 0,
    padding: {
      x: 2,
      top: 2,
      bottom: 0
    }
  })}
  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;

  & > * {
    grid-column-start: 1;
    margin-bottom: 0;
  }

  ${grid2MediaQuery} {
    & > * {
      grid-column-start: 2;
    }

    grid-template-columns: 1fr min(40rem, 70%);
  }

  ${grid3MediaQuery} {
    & > * {
      grid-column-start: 2;
    }

    grid-template-columns: 1fr 40rem 1fr;
  }

  & > p,
  & > ul,
  & > ol {
    ${sizedComponent({
      fontScaling: 20 / 16,
      margin: {
        top: 2,
        bottom: 0,
        x: 0
      },
      skipPadding: true
    })}
    max-width: 40rem;
  }
`

interface IPulledContainerProps {
  /** Position of the container on the grid */
  position?:
    | 'left'
    | 'right'
    | 'center-right'
    | 'center-right-full'
    | 'center-left'
    | 'center-left-full'
    | 'full'
    | 'center'
    | 'center-full'
  noMargin?: boolean
}

export const PostContentsContainer = styled.div<IPulledContainerProps>`
  ${({ noMargin = false }) =>
    sizedComponent({
      baseline: 'layout',
      padding: {
        x: 0,
        top: noMargin ? 0 : 2,
        bottom: 0
      },
      margin: {
        x: -2,
        y: 0
      }
    })}

  ${({ position = 'center' }) => {
    switch (position) {
      case 'left':
        return `
        margin: 0;
        grid-column-start: 1;
      `
      case 'right':
        return `
        margin: 0;
        grid-column-start: 1;

        ${grid2MediaQuery} {
          grid-column-start: 2;
        }

        ${grid3MediaQuery} {
          grid-column-start: 3;
        }
        `
      case 'center-right':
        return `
        grid-column: 1/2;

        ${grid2MediaQuery} {
          grid-column: 2/3;
          margin-left: 0;
        }

        ${grid3MediaQuery} {
          grid-column: 2/4;
          margin-left: 0;
        }
        `
      case 'center-right-full':
        return `
        grid-column: 1/2;

        ${grid2MediaQuery} {
          grid-column: 2/3;
        }

        ${grid3MediaQuery} {
          grid-column: 2/4;
        }
        `
      case 'center-left':
        return `
        grid-column: 1/2;

        ${grid2MediaQuery} {
          grid-column: 1/3;
          margin-right: 0;
        }

        ${grid3MediaQuery} {
          grid-column: 1/3;
          margin-right: 0;
        }
        `
      case 'center-left-full':
        return `
        grid-column: 1/2;

        ${grid2MediaQuery} {
          grid-column: 1/3;
        }

        ${grid3MediaQuery} {
          grid-column: 1/3;
        }
        `
      case 'full':
        return `
        grid-column: 1/2;

        ${grid2MediaQuery} {
          grid-column: 1/3;
        }

        ${grid3MediaQuery} {
          grid-column: 1/4;
        }
        `
      case 'center-full':
        return ''
      case 'center':
      default:
        return 'margin: 0;'
    }
  }}
`

export interface IPostTitleProps {
  /** Title */
  title?: ReactElement
  /** Inline title */
  inline?: boolean
}

export interface IPostProps
  extends IChildrenElementProps,
    IPostRendererProps,
    ISizedComponentProps,
    IPostTitleProps {}

export const SizeWrapper = styled.div`
  max-width: 15rem;

  ${sizedComponent({
    padding: {
      right: 2
    }
  })}

  ${grid2MediaQuery} {
    margin-left: auto;
  }

  ${grid3MediaQuery} {
    margin-left: auto;
  }
`

export const PostContentsTitle = ({
  title,
  inline = true
}: IPostTitleProps) => {
  const clonedTitle = title ? cloneElement(title, { inline }) : null

  return (
    <PostContentsContainer position="left">
      <SizeWrapper>{clonedTitle}</SizeWrapper>
    </PostContentsContainer>
  )
}

export interface IPostAuthorProps extends IChildrenElementProps {
  /** Author object */
  author: {
    /** Author first name */
    firstName: string
    /** Author last name */
    lastName: string
    /** Role */
    role?: string
    /** Is a member */
    isMember?: boolean
    /** Location */
    location?: string
    /** Linkedin profile */
    linkedin?: string
  }
  /** Author profile picture */
  picture?: Element
  /** Dates metadata */
  dates?: [string]
}

const AuthorCardRenderer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  ${sizedComponent({
    baseline: 'font',
    padding: {
      x: 0,
      top: 0,
      bottom: 1
    },
    margin: {
      x: -1,
      top: -1,
      bottom: 0
    }
  })}

  &:after {
    position: absolute;
    bottom: 0.5em;
    left: 1em;
    right: 1em;
    content: ' ';
    height: 1px;
    background-color: ${intents.grey.backgroundColor.default};
  }
`

const AvatarRenderer = styled.div`
  max-width: 6em;
  overflow: hidden;

  ${sizedComponent({
    baseline: 'font',
    padding: {
      x: 1,
      top: 1,
      bottom: 0
    }
  })}
`

const InfoRenderer = styled.div`
  display: block;

  flex: 1 0 12em;

  ${sizedComponent({
    baseline: 'font',
    padding: 1
  })}
`

const NameRenderer = styled.div`
  font-weight: bold;
  font-size: 1.2em;

  ${sizedComponent({
    baseline: 'font',
    padding: {
      bottom: 0.5
    }
  })}
`

const RoleRenderer = styled.div``

const DatesRenderer = styled.div`
  ${sizedComponent({
    padding: {
      bottom: 1.5
    }
  })}
`

const DateRenderer = styled.div`
  font-size: 0.9em;
  opacity: 0.5;
  ${sizedComponent({
    padding: {
      y: 0.125
    }
  })}
`

const ApprovedBadge = styled.div`
  color: ${intents.primary.backgroundColor.default};

  ${sizedComponent({
    padding: 0.4,
    margin: {
      x: 0.25
    }
  })}

  display: inline-block;
  border-radius: 100em;
  font-size: 0.7em;
  vertical-align: top;
  position: relative;
  top: -0.3em;
`

const PostAuthorWrapper = styled.div`
  position: relative;
  max-width: min(20em, 100%);

  ${grid2MediaQuery} {
    margin-left: auto;
  }

  ${grid3MediaQuery} {
    margin-left: auto;
  }
`

const SocialRenderer = styled.div`
  font-size: 0.8em;

  a {
    color: ${intents.grey.textColor.default};
    text-decoration: none;
    display: inline-block;
    opacity: 0.7;

    &:hover {
      text-decoration: underline;
      opacity: 1;
    }

    span {
      vertical-align: baseline;
      display: inline-block;
      font-size: 0.8em;
      svg {
        vertical-align: baseline;
        margin-left: 0.5em;
        position: relative;
      }
    }
  }
`

export const PostAuthor = ({
  author,
  picture = undefined,
  dates = undefined
}: IPostAuthorProps) => {
  return (
    <PostContentsContainer noMargin position="left">
      <PostAuthorWrapper>
        {dates && (
          <DatesRenderer>
            {dates.map((date, index) => {
              // eslint-disable-next-line react/no-array-index-key
              return <DateRenderer key={`${index}`}>{date}</DateRenderer>
            })}
          </DatesRenderer>
        )}
        <AuthorCardRenderer>
          {picture && <AvatarRenderer>{picture}</AvatarRenderer>}
          <InfoRenderer>
            <NameRenderer>
              {author.firstName} {author.lastName}
            </NameRenderer>
            {author.role && (
              <RoleRenderer>
                {author.role}
                {author.isMember ? (
                  <ApprovedBadge title="Team member">
                    <Icon icon={<UserAdmin32 />} />
                  </ApprovedBadge>
                ) : null}
              </RoleRenderer>
            )}
            {author.location && (
              <RoleRenderer style={{ opacity: '0.5' }}>
                {author.location}
              </RoleRenderer>
            )}
          </InfoRenderer>
        </AuthorCardRenderer>
        {author.linkedin && (
          <SocialRenderer>
            <a href={author.linkedin} target="_blank" rel="noreferrer">
              LinkedIn
              <Icon icon={<LogoLinkedin32 />} />
            </a>
          </SocialRenderer>
        )}
      </PostAuthorWrapper>
    </PostContentsContainer>
  )
}

export const Post = ({
  children,
  intent = 'white',
  title,
  inline = false,
  ...props
}: IPostProps) => {
  return (
    <PostRenderer intent={intent} {...props}>
      {title && <PostContentsTitle title={title} inline={inline} />}
      {flattenChildren(children).map((child) => {
        if (typeof child !== 'object') {
          return <p>{child}</p>
        }

        return child
      })}
    </PostRenderer>
  )
}

export interface IPostSectionProps {
  layout: 'centered' | 'left' | 'right' | 'full'
}
