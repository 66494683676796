import { useContext } from 'react'
import styled from 'styled-components'

import { Logo } from '~components/Brand/Logo'
import { useTracking } from '~components/Meta/Tracking'
import { NavContext } from '~components/UI/Nav/shared/Context'
import {
  coloredBackgroundComponent,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { focusRing } from '~fragments/interaction'
import { ISizedComponentProps } from '~fragments/size'
import {
  IGenericElementProps,
  ITrackableElement,
  IWithAsElementProps
} from '~typings/props'
import { navSizes, sizes, unit } from '~variables/sizes'

export interface INavMainLogoWrapperProps
  extends IColoredBackgroundComponentProps,
    ISizedComponentProps {
  /** Make the logo interactive */
  interactive: boolean
}

const NavMainLogoWrapper = styled.span<INavMainLogoWrapperProps>`
  display: block;
  flex: 1 0 auto;
  padding: ${({ size = 'large' }) =>
    ((sizes[size].baseHeightFactor - navSizes.main.logoHeight) / 2) * unit}rem;

  ${({ interactive = false }) =>
    coloredBackgroundComponent({
      interactive
    })}

  ${({ interactive = false }) =>
    interactive
      ? `
  ${focusRing}

  &:not(:focus-visible) {
    box-shadow: none;
  }
    `
      : ''}

  svg {
    height: ${navSizes.main.logoHeight * unit}rem;
  }
`

export const NavMainLogo = ({
  ...props
}: IWithAsElementProps<INavMainLogoWrapperProps> &
  ITrackableElement &
  IGenericElementProps) => {
  const { intent, size } = useContext(NavContext)

  const trackingClickHandler = useTracking({
    type: 'event',
    name: 'click',
    properties: {
      component: 'factor.nav-link',
      trackId: 'nav.logo'
    }
  })

  const clickHandler = () => {
    trackingClickHandler()

    if (props && props.onClick) props.onClick()
  }

  return (
    <NavMainLogoWrapper
      size={size}
      intent={intent}
      {...props}
      onClick={clickHandler}
    >
      <Logo />
    </NavMainLogoWrapper>
  )
}

export default NavMainLogo
