import AU from 'flagpack-core/lib/flags/l/AU.svg'
import BE from 'flagpack-core/lib/flags/l/BE.svg'
import CA from 'flagpack-core/lib/flags/l/CA.svg'
import CH from 'flagpack-core/lib/flags/l/CH.svg'
import FR from 'flagpack-core/lib/flags/l/FR.svg'
import GBR from 'flagpack-core/lib/flags/l/GBR.svg'
import IE from 'flagpack-core/lib/flags/l/IE.svg'
import LU from 'flagpack-core/lib/flags/l/LU.svg'
import MX from 'flagpack-core/lib/flags/l/MX.svg'
import NZ from 'flagpack-core/lib/flags/l/NZ.svg'
import SG from 'flagpack-core/lib/flags/l/SG.svg'
import US from 'flagpack-core/lib/flags/l/US.svg'
import styled from 'styled-components'

const flags = {
  AU,
  BE,
  CA,
  CH,
  FR,
  GBR,
  IE,
  LU,
  MX,
  NZ,
  SG,
  US
}

export type TSupportedFlags = keyof typeof flags

const FlagContainer = styled.div`
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  &.size {
    &-s {
      width: 16px;
      height: 12px;

      &.drop-shadow {
        box-shadow: 0 0 1px 0.5px rgba(0, 0, 0, 0.1);
      }

      &.border-radius {
        border-radius: 1px;

        &.border {
          &::before {
            border-radius: 1px;
          }
        }
      }
    }

    &-m {
      width: 20px;
      height: 15px;

      &.drop-shadow {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      }

      &.border-radius {
        border-radius: 1.5px;

        &.border {
          &::before {
            border-radius: 1.5px;
          }
        }
      }
    }

    &-l {
      width: 32px;
      height: 24px;

      &.drop-shadow {
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
      }

      &.border-radius {
        border-radius: 2px;

        &.border {
          &::before {
            border-radius: 2px;
          }
        }
      }
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    mix-blend-mode: overlay;
    box-sizing: border-box;
  }

  &.border {
    &::before {
      border: 1px solid rgba(0, 0, 0, 0.5);
      mix-blend-mode: overlay;
    }
  }

  &.top-down {
    &::before {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3) 2%,
        rgba(255, 255, 255, 0.7) 100%
      );
    }
  }

  &.real-linear {
    &::before {
      background-image: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(39, 39, 39, 0.22) 11%,
        rgba(255, 255, 255, 0.3) 27%,
        rgba(0, 0, 0, 0.24) 41%,
        rgba(0, 0, 0, 0.55) 52%,
        rgba(255, 255, 255, 0.26) 63%,
        rgba(0, 0, 0, 0.27) 74%,
        rgba(255, 255, 255, 0.3) 100%
      );
    }
  }

  &.real-circular {
    &::before {
      background: radial-gradient(
            50% 36%,
            rgba(255, 255, 255, 0.3) 0%,
            rgba(0, 0, 0, 0.24) 11%,
            rgba(0, 0, 0, 0.55) 17%,
            rgba(255, 255, 255, 0.26) 22%,
            rgba(0, 0, 0, 0.17) 27%,
            rgba(255, 255, 255, 0.28) 31%,
            rgba(255, 255, 255, 0) 37%
          )
          center calc(50% - 8px) / 600% 600%,
        radial-gradient(
            50% 123%,
            rgba(255, 255, 255, 0.3) 25%,
            rgba(0, 0, 0, 0.24) 48%,
            rgba(0, 0, 0, 0.55) 61%,
            rgba(255, 255, 255, 0.26) 72%,
            rgba(0, 0, 0, 0.17) 80%,
            rgba(255, 255, 255, 0.28) 88%,
            rgba(255, 255, 255, 0.3) 100%
          )
          center calc(50% - 8px) / 600% 600%;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Flag = ({ code }: { code: keyof typeof flags }) => {
  return (
    <FlagContainer className="flag size-l border border-radius">
      <img src={flags[code]} alt={code} />
    </FlagContainer>
  )
}
