import {
  CaretDown32,
  CaretUp32,
  EarthAmericasFilled32,
  EarthEuropeAfricaFilled32,
  EarthFilled32,
  EarthSoutheastAsiaFilled32
} from '@carbon/icons-react'
import Cookies from 'js-cookie'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { Button } from '~components/UI/Button'
import { Card } from '~components/UI/Card'
import { Icon } from '~components/UI/Icon'
import { Modal } from '~components/UI/Modal'
import { IColoredBackgroundComponentProps } from '~fragments/color'
import { sizedComponent } from '~fragments/size'
import { intents } from '~variables/colors'

import { FooterContext } from '../context'
import { Flag, TSupportedFlags } from './flag'

export interface ILanguageSwitcherCountryLanguage {
  /** Language name */
  name: string
  /** Language code */
  code: TSupportedFlags
}

const regionsMap = {
  Europe: {
    icon: <EarthEuropeAfricaFilled32 />
  },
  Africa: {
    icon: <EarthEuropeAfricaFilled32 />
  },
  America: {
    icon: <EarthAmericasFilled32 />
  },
  Oceania: {
    icon: <EarthSoutheastAsiaFilled32 />
  },
  Asia: {
    icon: <EarthSoutheastAsiaFilled32 />
  },
  World: {
    icon: <EarthFilled32 />
  }
}

export type TRegionNames = keyof typeof regionsMap

export interface ILanguageSwitcherRegion {
  /** Country region */
  region: TRegionNames
  /** Country region */
  name: string
}

export interface ILanguageSwitcherCountry {
  /** Country name */
  name?: string
  /** Country region */
  region: ILanguageSwitcherRegion
  /** Country flag code */
  flag?: TSupportedFlags
  /** Languages of the country */
  languages: ILanguageSwitcherCountryLanguage[]
}

export interface ILanguageSwitcherProps {
  /** List of available countries */
  countries: ILanguageSwitcherCountry[]
  /** Currently active country */
  active?: string
  /** Handler to change language */
  changeLanguage?: (language: string) => void
  /** Popup title */
  title?: string
}

export interface ILanguageSwitcherFlagIconProps {
  code?: TSupportedFlags
}

const FlagIconRenderer = styled.span`
  display: inline-block;
  height: 1.5em;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 1.5em;

  & > div {
    height: auto !important;
    width: 100% !important;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    & > img {
      width: inherit !important;
      height: auto !important;
    }
  }
`

export const LanguageSwitcherFlagIcon = ({
  code,
  ...props
}: ILanguageSwitcherFlagIconProps) => {
  return (
    <Icon
      {...props}
      icon={
        (code && (
          <FlagIconRenderer>
            <Flag code={code} />
          </FlagIconRenderer>
        )) || <EarthFilled32 />
      }
    />
  )
}

export interface ILanguageSwitcherCurrentLanguageProps {
  country?: ILanguageSwitcherCountry
  onClick?: () => void
}

const LanguageSwitcherCurrentLanguageRenderer = styled.div`
  ${sizedComponent({
    margin: { x: -1, y: 0 },
    baseline: 'font',
    padding: 0
  })}
`

export const LanguageSwitcherCurrentLanguage = ({
  country,
  ...props
}: ILanguageSwitcherCurrentLanguageProps) => {
  return (
    <LanguageSwitcherCurrentLanguageRenderer>
      <FooterContext.Consumer>
        {({ intent }) => (
          <Button
            intent={intent}
            leftIcon={
              <LanguageSwitcherFlagIcon
                code={(country && country.flag) || undefined}
              />
            }
            {...props}
          >
            {(country && (country.name || country.region.name)) || 'World'}
          </Button>
        )}
      </FooterContext.Consumer>
    </LanguageSwitcherCurrentLanguageRenderer>
  )
}

const RegionRenderer = styled.div<IColoredBackgroundComponentProps>``

const RegionGridRenderer = styled.div<IColoredBackgroundComponentProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 1px;

  ${({ intent = 'secondary' }) => {
    return `
    overflow: hidden;

    & > *:not(:last-child) {
      outline: 1px solid ${intents[intent].backgroundColor.hover};
    }

    border-top: 1px solid ${intents[intent].backgroundColor.hover};
  `
  }}
`

const RegionCountriesRenderer = styled.div``

export interface IRegionProps {
  region: ILanguageSwitcherGroupedRegion
  changeLanguage?: (language: string) => void
  close: () => void
}

const Region = ({
  region,
  changeLanguage = (language) => {
    // eslint-disable-next-line
    console.warn(
      `Tried changing language to ${language} but no listener is configured`
    )
  },
  close
}: IRegionProps) => {
  const [isOpen, setIsOpen] = useState(region.region === 'World')

  return (
    <RegionRenderer intent="secondary">
      {region.region !== 'World' && (
        <Button
          size="large"
          intent="secondary"
          leftIcon={<Icon icon={regionsMap[region.region].icon} />}
          rightIcon={<Icon icon={isOpen ? <CaretUp32 /> : <CaretDown32 />} />}
          fullWidth
          onClick={() => setIsOpen(!isOpen)}
        >
          {region.name}
        </Button>
      )}
      <RegionCountriesRenderer style={{ display: isOpen ? 'block' : 'none' }}>
        {region.countries.map((country) => {
          return country.languages.map((language) => (
            <Button
              key={`${country.name}-${language.name}`}
              size="large"
              leftIcon={<LanguageSwitcherFlagIcon code={country.flag} />}
              fullWidth
              intent="secondary"
              onClick={() => {
                if (changeLanguage) changeLanguage(language.code)
                close()
              }}
              trackId={`language-switcher.${region.name}.${country.name}`}
            >
              {`${country.name || region.name} (${language.name})`}
            </Button>
          ))
        })}
      </RegionCountriesRenderer>
    </RegionRenderer>
  )
}

interface ILanguageSwitcherGroupedRegion extends ILanguageSwitcherRegion {
  countries: ILanguageSwitcherCountry[]
}

export const LanguageSwitcher = ({
  countries,
  active,
  changeLanguage,
  title
}: ILanguageSwitcherProps) => {
  const activeCountry = countries.find((country) => {
    let found = false

    country.languages.forEach((language) => {
      if (language.code === active) found = true
    })

    return found
  })

  const regions = useMemo(() => {
    const regions: {
      [key in TRegionNames]?: ILanguageSwitcherGroupedRegion
    } = {}

    countries.forEach((country) => {
      if (!regions[country.region.region]) {
        regions[country.region.region] = {
          ...country.region,
          countries: []
        }
      }

      regions[country.region.region]?.countries?.push(country)
    })

    return regions
  }, [countries])

  const changeLanguageWrapper = (language: string) => {
    const cookies = Cookies.get()

    Object.keys(cookies).forEach((cookieName) => {
      if (cookieName.substring(0, 5) === 'lang-') {
        Cookies.remove(cookieName)
      }
    })

    Cookies.set(`lang-${language}`, 'prefer', {
      expires: 366
    })

    if (changeLanguage) changeLanguage(language)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <div>
      <LanguageSwitcherCurrentLanguage
        onClick={() => {
          setIsModalVisible(true)
        }}
        country={activeCountry}
      />
      <Modal
        disposition="small"
        onClose={() => setIsModalVisible(false)}
        open={isModalVisible}
        noPadding
        intent="secondary"
      >
        <Card
          intent="secondary"
          title={title || 'Select region'}
          icon={<Icon icon={<EarthFilled32 />} />}
        />
        <RegionGridRenderer>
          {Object.keys(regions).map((regionName) => {
            const regionNameKey = regionName as TRegionNames

            const region = regions[regionNameKey]

            if (region)
              return (
                <Region
                  key={regionName}
                  changeLanguage={changeLanguageWrapper}
                  close={() => setIsModalVisible(false)}
                  region={region}
                />
              )
            return null
          })}
        </RegionGridRenderer>
      </Modal>
    </div>
  )
}
