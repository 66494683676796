import { createGlobalStyle, css } from 'styled-components'

import { lineHeightRatio, referenceFontFactor, unit } from '~variables/sizes'

const referenceFontSize = referenceFontFactor * unit

export const h0 = css`
  font-size: clamp(
    ${referenceFontSize * 4}rem,
    6vw,
    ${referenceFontSize * 5}rem
  );
`

export const h1 = css`
  font-size: ${referenceFontSize * 3.5}rem;
`

export const h4 = css`
  font-size: ${referenceFontSize * 1.25}rem;
`

export const h5 = css`
  font-size: ${referenceFontSize * 1.25}rem;
`

export const p = css`
  font-size: ${referenceFontSize}rem;
`

export const Typography = createGlobalStyle`
  html {
    p {
      font-size: ${referenceFontSize}rem;
    }

    h1,h2,h3,h4,h5,h6,p,blockquote {
      font-weight: 400;
      line-height: ${lineHeightRatio}em;
    }

    h1 {
      ${h1}
    }

    h2 {
      font-size: ${referenceFontSize * 2.5}rem;
    }

    h3 {
      font-size: ${referenceFontSize * 1.5}rem;
    }

    h4 {
      ${h4}
    }

    blockquote {
      padding: 1em 2em;
      margin: 1em 0em ;
      font-size: 1.4em;
      font-style: italic;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 1em;
        left: 0;
        bottom: 1em;
        width: 2px;
        background-color: currentColor;
        opacity: .1;
      }

      p {
        font-size: 1em;
        margin: 1em;

        &:last-child:after {
          content: ' —';
          opacity: .1;
        }
      }
    }
  }
`
