import { createGlobalStyle } from 'styled-components'

import Fonts from '~fragments/fonts'
import { defaultFontSize } from '~variables/sizes'

import Normalize from './normalize'
import { Typography } from './typography'

const BaseSize = createGlobalStyle`
  html {
    font-size: ${defaultFontSize / 16}em;

    @media only screen and (max-width: 50em) {
      font-size: 0.875em;
    }

    @media only screen and (min-width: 50em) and (max-width: 100em) {
      font-size: 1em;
    }

    @media only screen and (min-width: 100em) {
      font-size: 1vw;
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

  body, html {
    height: 100%;
  }
`

export const GlobalStyles = (): JSX.Element => {
  return (
    <>
      <Normalize />
      <BaseSize />
      <Fonts />
      <Typography />
    </>
  )
}

export default GlobalStyles
