import { ReactNode } from 'react'
import styled from 'styled-components'

import { IColoredBackgroundComponentProps } from '~fragments/color'
import { intents } from '~variables/colors'
import { lineHeightRatio } from '~variables/sizes'

export interface IIconProps extends IColoredBackgroundComponentProps {
  icon: ReactNode
}

export const IconRenderer = styled.span<IColoredBackgroundComponentProps>`
  display: inline-block;
  width: ${lineHeightRatio}em;
  height: 1em;
  vertical-align: top;

  color: ${({ intent }) =>
    intent ? intents[intent].textColor.default : 'inherit'};

  & > * {
    position: relative;
    top: -0.125em;
    left: -0.125em;
    display: block;
    width: 1.5em;
    height: 1.5em;
  }
`

export const Icon = ({ icon, ...props }: IIconProps) => {
  return <IconRenderer {...props}>{icon}</IconRenderer>
}

export default Icon
