import styled from 'styled-components'

import { IChildrenElementProps } from '~typings/props'
import { navSizes, unit } from '~variables/sizes'

export * from '~components/UI/Nav/Context'
export * from '~components/UI/Nav/Main'
export * from '~components/UI/Nav/Sub'

const NavRenderer = styled.nav`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: ${navSizes.breakpoint * unit}rem) {
    left: 1rem;
    right: 1rem;
  }
`

export const Nav = ({ children }: IChildrenElementProps) => {
  return <NavRenderer>{children}</NavRenderer>
}
