import Color from 'color'

import { shadowColor, TColor } from '~variables/colors'

/**
 * Generate a box-shadow CSS declaration with the given params
 * @param size Size of the outermost layer of the shadow
 * @param color Color of the shadow (with transparency)
 * @param layers Number of layers (integer)
 */
export const generateBoxShadow = (
  size: number,
  color: TColor,
  layers: number
) => {
  let declaration = ''

  for (let i = 1; i <= layers; i += 1) {
    const fraction = i / Math.floor(layers)
    const offset = ((fraction ** 2 * size) / 2).toFixed(3)
    const blur = (fraction ** 2 * size).toFixed(3)
    const layerColor = new Color(color)
      .fade((1 - fraction ** 2) / 2)
      .rgb()
      .toString()

    declaration += `0 ${offset}rem ${blur}rem ${layerColor},`
  }

  declaration = declaration.substring(0, declaration.length - 1)

  return declaration
}

export const shadowLarge = generateBoxShadow(3, shadowColor, 6)

export const shadowNormal = generateBoxShadow(1.5, shadowColor, 3)
