import { PostContentsContainer, CardGrid, Card, PostContentsTitle, Title, Icon } from "@lleed-partners/factor";
import { Events32, Loop32, Workspace32, Code32 } from "@carbon/icons-react";
import * as React from 'react';
export default {
  PostContentsContainer,
  CardGrid,
  Card,
  PostContentsTitle,
  Title,
  Icon,
  Events32,
  Loop32,
  Workspace32,
  Code32,
  React
};