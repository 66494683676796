import styled from 'styled-components/macro'

import { useTracking } from '~components/Meta/Tracking'
import { onClickARIA } from '~fragments/aria'
import {
  coloredBackgroundComponent,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { disableComponent, IDisableComponentProps } from '~fragments/disabled'
import { GroupContainer, GroupElement } from '~fragments/group'
import { noUserSelect, pointerInteractive } from '~fragments/interaction'
import { ISizedBoxComponentProps, sizedBoxComponent } from '~fragments/size'
import { ISkeletonProps, skeleton } from '~fragments/skeleton'
import {
  IChildrenElementProps,
  ITrackableElement,
  IWithAsElementProps,
  TIconType
} from '~typings/props'

export interface IButtonRendererProps
  extends ISizedBoxComponentProps,
    IColoredBackgroundComponentProps,
    IChildrenElementProps,
    ISkeletonProps,
    IDisableComponentProps {
  /** Disables text wrapping */
  noWrap?: boolean
  /** Takes all available width */
  fullWidth?: boolean
  /** Fake button */
  fake?: boolean
}

export interface IButtonProps
  extends IButtonRendererProps,
    ITrackableElement,
    IWithAsElementProps<IButtonRendererProps> {
  /** Left icon */
  leftIcon?: TIconType
  /** Right icon */
  rightIcon?: TIconType
}

export const StyledButton = styled.span<IButtonRendererProps>`
  padding: 0;
  margin: 0;

  ${sizedBoxComponent()}
  ${noUserSelect}
  ${(props) => (props.fake ? '' : pointerInteractive)}
  ${(props) => coloredBackgroundComponent({ interactive: !props.fake })}
  ${skeleton}
  ${disableComponent}

  font-weight: 400;

  ${({ noWrap }) => (noWrap ? 'white-space: nowrap;' : '')}

  ${({ fullWidth = false }) => (fullWidth ? 'display: block;' : '')}
`

export const Button = ({
  children,
  size = 'normal',
  intent = 'primary',
  disabled = false,
  skeleton = false,
  leftIcon,
  rightIcon,
  noWrap = false,
  fullWidth = false,
  trackId,
  ...props
}: IButtonProps): JSX.Element => {
  const appliedProps = {
    noWrap,
    size,
    intent,
    skeleton,
    fullWidth,
    ...props
  }

  const trackingClickHandler = useTracking({
    type: 'event',
    name: 'click',
    properties: {
      component: 'factor.button',
      trackId
    }
  })

  const clickHandler = () => {
    trackingClickHandler()

    if (props && props.onClick) props.onClick()
  }

  return (
    <StyledButton
      tabIndex={props.onClick ? 0 : undefined}
      onKeyDown={props.onClick ? onClickARIA(clickHandler) : undefined}
      disabled={disabled || skeleton}
      {...appliedProps}
      onClick={clickHandler}
    >
      <GroupContainer as={fullWidth ? 'div' : 'span'}>
        {leftIcon && <GroupElement>{leftIcon}</GroupElement>}
        {(children || (!rightIcon && !leftIcon)) && (
          <GroupElement>{children}</GroupElement>
        )}
        {fullWidth ? <span style={{ flex: '1 0 0' }} /> : null}
        {rightIcon && <GroupElement>{rightIcon}</GroupElement>}
      </GroupContainer>
    </StyledButton>
  )
}
