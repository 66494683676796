import { Checkmark32 } from '@carbon/icons-react'
import styled from 'styled-components'

import { Icon, IconRenderer } from '~components/UI/Icon'
import {
  coloredBackgroundComponent,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { GroupContainer, GroupElement } from '~fragments/group'
import {
  focusRing,
  focusRingVisible,
  noUserSelect,
  pointerInteractive
} from '~fragments/interaction'
import {
  ISizedBoxComponentProps,
  ISizedComponentProps,
  sizedBoxComponent,
  sizedComponent
} from '~fragments/size'
import { IChildrenElementProps, IGenericElementProps } from '~typings/props'

const FormElementWrapper = styled.div`
  position: relative;

  ${sizedComponent({
    padding: {
      x: 0,
      y: 0.5
    },
    baseline: 'font'
  })}
`

const FormLabelTextRenderer = styled.span`
  ${noUserSelect}
`

export interface IFormLabelRendererProps {
  /** Whether or not the input is required */
  required?: boolean
}

export const FormLabelRenderer = styled.label<IFormLabelRendererProps>`
  display: block;

  & > ${FormLabelTextRenderer} {
    display: block;
    ${sizedComponent({
      padding: {
        x: 0,
        y: 1
      },
      baseline: 'font'
    })}

    ${({ required = false }) =>
      required
        ? `
      &:after {
        content: "*";
        color: red;
      }
    `
        : ''}
  }
`

export const FormInputRenderer = styled.input<
  IColoredBackgroundComponentProps & ISizedComponentProps
>`
  width: 100%;
  box-sizing: border-box;
  border-style: solid;
  border-radius: 0;

  ${sizedComponent({
    border: true,
    padding: 1,
    baseline: 'font'
  })}

  ${coloredBackgroundComponent({
    interactive: false
  })}

  ${focusRing}

  resize: vertical;
`

export interface IFormInputProps
  extends IGenericElementProps,
    IColoredBackgroundComponentProps,
    IFormLabelRendererProps {
  /** Label of the form element */
  label?: string
}

export const FormInput = ({
  intent = 'grey',
  label,
  required = false,
  ...props
}: IFormInputProps) => {
  return (
    <FormElementWrapper>
      <FormLabelRenderer required={required}>
        {label && <FormLabelTextRenderer>{label}</FormLabelTextRenderer>}
        <FormInputRenderer intent={intent} required={required} {...props} />
      </FormLabelRenderer>
    </FormElementWrapper>
  )
}

const FormCheckboxRenderer = styled.span<
  IColoredBackgroundComponentProps & ISizedBoxComponentProps
>`
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;

  ${focusRing}
  ${sizedBoxComponent()}
  ${pointerInteractive}
  ${coloredBackgroundComponent({
    interactive: true
  })}

  display: inline-block;
`

const FormCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ${noUserSelect}

  input[type='checkbox'] {
    height: 0px !important;
    width: 0px !important;
    margin: 0 !important;
    box-sizing: border-box;

    & + ${FormCheckboxRenderer} ${IconRenderer} {
      opacity: 0;
    }

    &:checked + ${FormCheckboxRenderer} ${IconRenderer} {
      opacity: 1;
    }

    &:focus-visible + ${FormCheckboxRenderer} {
      ${focusRingVisible}
    }
  }
`

const FormCheckboxSideLabelRenderer = styled.span<ISizedBoxComponentProps>`
  ${sizedComponent({
    padding: {
      y: 0,
      x: 1
    }
  })}

  ${sizedBoxComponent()}
`

export const FormCheckbox = ({
  intent = 'grey',
  label,
  children,
  size = 'small',
  required = false,
  ...props
}: IFormInputProps & IChildrenElementProps & ISizedBoxComponentProps) => {
  return (
    <FormElementWrapper>
      <FormLabelRenderer required={required}>
        {label && <FormLabelTextRenderer>{label}</FormLabelTextRenderer>}
        <FormCheckboxWrapper>
          <input type="checkbox" {...props} />
          <FormCheckboxRenderer intent={intent} size={size}>
            <GroupContainer as="span">
              <GroupElement>
                <Icon icon={<Checkmark32 />} />
              </GroupElement>
            </GroupContainer>
          </FormCheckboxRenderer>
          {children && (
            <FormCheckboxSideLabelRenderer size={size}>
              <GroupContainer as="span">
                <GroupElement>{children}</GroupElement>
              </GroupContainer>
            </FormCheckboxSideLabelRenderer>
          )}
        </FormCheckboxWrapper>
      </FormLabelRenderer>
    </FormElementWrapper>
  )
}
