import { useContext } from 'react'
import styled from 'styled-components'

import { useTracking } from '~components/Meta/Tracking'
import { NavContext } from '~components/UI/Nav/shared/Context'
import { coloredBackgroundComponent } from '~fragments/color'
import { GroupContainer, GroupElement } from '~fragments/group'
import { noUserSelect, pointerInteractive } from '~fragments/interaction'
import { sizedBoxComponent } from '~fragments/size'
import {
  IChildrenElementProps,
  IGenericElementProps,
  ITrackableElement,
  IWithAsElementProps
} from '~typings/props'
import { TIntents } from '~variables/colors'
import { focusRingSize, TSizes } from '~variables/sizes'

export interface ILinkRendererProps {
  /** Size object for the element */
  size: TSizes
  /** Intent */
  intent: TIntents
  /** Whether or not the link is active */
  active?: boolean
}

export const LinkRenderer = styled.a<ILinkRendererProps>`
  ${sizedBoxComponent()}
  ${noUserSelect}
  ${pointerInteractive}
  ${({ active = false }) =>
    coloredBackgroundComponent({
      interactive: !active
    })}

  position: relative;

  ${({ active = false }) => {
    return active
      ? `
    &:after {
      content: '';
      position: absolute;
      left: -${focusRingSize}px;
      bottom: -${focusRingSize}px;
      right: -${focusRingSize}px;
      height: ${focusRingSize}px;
      background-color: white;
    }
  `
      : ''
  }}

  display: block;
`

export interface ILinkProps
  extends Omit<ILinkRendererProps, 'size' | 'intent'>,
    IWithAsElementProps<ILinkRendererProps>,
    IChildrenElementProps,
    ITrackableElement,
    IGenericElementProps {
  /** Intent */
  intent?: TIntents
}

export const Link = ({ children, ...props }: ILinkProps) => {
  const { intent, size } = useContext(NavContext)

  const trackingClickHandler = useTracking({
    type: 'event',
    name: 'click',
    properties: {
      component: 'factor.nav-logo'
    }
  })

  const clickHandler = () => {
    trackingClickHandler()

    if (props && props.onClick) props.onClick()
  }

  return (
    <LinkRenderer intent={intent} size={size} {...props} onClick={clickHandler}>
      <GroupContainer>
        <GroupElement>{children}</GroupElement>
      </GroupContainer>
    </LinkRenderer>
  )
}
