import { PostContentsContainer, Media, CardGrid, Card, PostContentsTitle, Title, SizeWrapper } from "@lleed-partners/factor";
import { StaticImage } from "gatsby-plugin-image";
import * as React from 'react';
export default {
  PostContentsContainer,
  Media,
  CardGrid,
  Card,
  PostContentsTitle,
  Title,
  SizeWrapper,
  StaticImage,
  React
};