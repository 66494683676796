import { PostContentsContainer, Card, CardGrid, CardList, Icon, PostContentsTitle, Title } from "@lleed-partners/factor";
import { ScanAlt32, Archive32, Delivery32 } from "@carbon/icons-react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from 'react';
export default {
  PostContentsContainer,
  Card,
  CardGrid,
  CardList,
  Icon,
  PostContentsTitle,
  Title,
  ScanAlt32,
  Archive32,
  Delivery32,
  GatsbyImage,
  getImage,
  React
};