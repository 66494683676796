import styled from 'styled-components'

import { NavContext } from '~components/UI/Nav/shared/Context'
import {
  coloredBackgroundComponent,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { IChildrenElementProps } from '~typings/props'
import { intents } from '~variables/colors'

interface INavSubRendererProps
  extends IChildrenElementProps,
    IColoredBackgroundComponentProps {}

const NavSeparator = styled.div<IColoredBackgroundComponentProps>`
  background-color: ${({ intent = 'dark' }) =>
    intents[intent].backgroundColor.hover};
  height: 1px;
`

const NavSubRenderer = styled.div<INavSubRendererProps>`
  ${coloredBackgroundComponent({ interactive: false })}
  display: flex;
  flex-direction: row;

  flex-wrap: nowrap;

  position: relative;
  z-index: 100;
  width: 100%;
  overflow: auto;
`

export type INavSubProps = INavSubRendererProps

export const NavSub = ({ children, intent }: INavSubProps) => {
  return (
    <NavContext.Consumer>
      {(navContext) => {
        return (
          <NavContext.Provider
            value={{ ...navContext, intent: intent || navContext.intent }}
          >
            <NavContext.Consumer>
              {({ intent }) => {
                return (
                  <>
                    <NavSeparator />
                    <NavSubRenderer intent={intent}>{children}</NavSubRenderer>
                  </>
                )
              }}
            </NavContext.Consumer>
          </NavContext.Provider>
        )
      }}
    </NavContext.Consumer>
  )
}
