import { createGlobalStyle } from 'styled-components'

import * as agrandirGrand from './agrandir/grand/index.css'
import * as agrandir from './agrandir/normal/index.css'
import * as basierCircleMono from './basier-circle/mono/index.css'
import * as basierCircle from './basier-circle/standard/index.css'

export const BasierCircleFontFace = createGlobalStyle`
  ${basierCircle}
`

export const basierCircleFontFamily =
  '"Basier Circle", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

export const BasierCircleMonoFontFace = createGlobalStyle`
  ${basierCircleMono}
`

export const basierCircleMonoFontFamily =
  '"Basier Circle Mono", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace'

export const AgrandirFontFace = createGlobalStyle`
  ${agrandir}
`

export const agrandirFontFamily = `Agrandir, ${basierCircleFontFamily}}`

export const AgrandirGrandFontFace = createGlobalStyle`
  ${agrandirGrand}
`

export const agrandirGrandFontFamily = `"Agrandir Grand", ${basierCircleFontFamily}}`

export const DefaultFontset = createGlobalStyle`
  html, body {
    font-family: ${basierCircleFontFamily};
  }
`

export default (): JSX.Element => {
  return (
    <>
      <BasierCircleFontFace />
      <BasierCircleMonoFontFace />
      <AgrandirFontFace />
      <AgrandirGrandFontFace />
      <DefaultFontset />
    </>
  )
}
