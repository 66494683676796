import { PostContentsContainer, Media, CardGrid, Card, PostContentsTitle, Title, SizeWrapper } from "@lleed-partners/factor";
import { View32, ShoppingCart32, WarningAlt32, TableSplit32 } from "@carbon/icons-react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from 'react';
export default {
  PostContentsContainer,
  Media,
  CardGrid,
  Card,
  PostContentsTitle,
  Title,
  SizeWrapper,
  View32,
  ShoppingCart32,
  WarningAlt32,
  TableSplit32,
  GatsbyImage,
  getImage,
  React
};