import Color from 'color'

/** Base types definition */
export type TColor = string

interface IColorScale {
  shades: [TColor, TColor, TColor]
  contrast: TColor
}

/** Helper functions */

/**
 * Darkens a color by a given amount
 * @param color Base color (as hex, rgb, name, ...)
 * @param amount Darken amount (on a 0 to 1 scale)
 */
const darken = (color: TColor, amount: number): TColor => {
  return new Color(color)
    .saturate(amount * 0.2)
    .mix(new Color('black'), amount)
    .hex()
}

/**
 * Lightens a color by a given amount
 * @param color Base color (as hex, rgb, name, ...)
 * @param amount Lighten amount (on a 0 to 1 scale)
 */
const lighten = (color: TColor, amount: number): TColor => {
  return new Color(color)
    .saturate(amount * 0.2)
    .mix(new Color('white'), amount)
    .hex()
}

/** Base colors definition */
export const baseWhite = '#FFFFFF'
export const baseBlue = '#3947E4'
export const baseBlack = new Color(baseBlue).darken(1).hex()
export const baseLightGrey = new Color(baseBlue)
  .desaturate(0.95)
  .lighten(0.5)
  .hex()
export const baseDarkGrey = new Color(baseBlue)
  .desaturate(0.95)
  .darken(0.7)
  .hex()

/** Shadow color */
export const shadowColor = new Color(baseDarkGrey).fade(0.75).rgb().toString()

/** Color scales */
export const blue: IColorScale = {
  shades: [darken(baseBlue, 0), darken(baseBlue, 0.2), darken(baseBlue, 0.4)],
  contrast: baseWhite
}

export const lightGrey: IColorScale = {
  shades: [
    lighten(baseLightGrey, 0.6),
    lighten(baseLightGrey, 0.4),
    lighten(baseLightGrey, 0.2)
  ],
  contrast: baseBlack
}

export const darkGrey: IColorScale = {
  shades: [
    darken(baseDarkGrey, 0),
    darken(baseDarkGrey, 0.2),
    darken(baseDarkGrey, 0.4)
  ],
  contrast: baseWhite
}

export const black: IColorScale = {
  shades: [darken(baseDarkGrey, 0.4), darken(baseDarkGrey, 0.2), baseBlack],
  contrast: baseWhite
}

export default {
  blue,
  lightGrey,
  darkGrey
}

export const skeletonForegroundColor = lightGrey.shades[0]
export const skeletonBackgroundColor = lightGrey.shades[2]

/**
 * Intents
 */
export interface IIntent {
  textColor: {
    default: TColor
    hover?: TColor
    disabled?: TColor
  }
  backgroundColor: {
    default: TColor
    hover: TColor
    focus: TColor
    disabled?: TColor
  }
  noBorderColor?: boolean
}

export type TIntents =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'ghostInverted'
  | 'dark'
  | 'base'
  | 'grey'
  | 'white'

export const base: IIntent = {
  textColor: {
    default: baseBlack,
    disabled: baseDarkGrey
  },
  backgroundColor: {
    default: 'transparent',
    hover: new Color(baseLightGrey).fade(0.8).rgb().string(),
    focus: new Color(baseLightGrey).fade(0.6).rgb().string()
  }
}

export const white: IIntent = {
  textColor: {
    default: baseBlack,
    disabled: baseDarkGrey
  },
  backgroundColor: {
    default: baseWhite,
    hover: lightGrey.shades[0],
    focus: lightGrey.shades[1]
  }
}

export const primary: IIntent = {
  textColor: {
    default: blue.contrast,
    disabled: baseDarkGrey
  },
  backgroundColor: {
    default: blue.shades[0],
    hover: blue.shades[1],
    focus: blue.shades[2],
    disabled: baseLightGrey
  }
}

export const secondary: IIntent = {
  textColor: {
    default: darkGrey.contrast,
    disabled: baseDarkGrey
  },
  backgroundColor: {
    default: darkGrey.shades[0],
    hover: darkGrey.shades[1],
    focus: darkGrey.shades[2],
    disabled: baseLightGrey
  }
}

export const ghost: IIntent = {
  textColor: {
    default: baseBlue,
    disabled: baseLightGrey
  },
  backgroundColor: {
    default: new Color(baseLightGrey).fade(1).rgb().string(),
    hover: new Color(baseLightGrey).fade(0.8).rgb().string(),
    focus: new Color(baseLightGrey).fade(0.6).rgb().string()
  },
  noBorderColor: true
}

export const ghostInverted: IIntent = {
  textColor: {
    default: baseWhite,
    disabled: baseDarkGrey
  },
  backgroundColor: {
    default: new Color(baseLightGrey).fade(1).rgb().string(),
    hover: new Color(baseLightGrey).fade(0.8).rgb().string(),
    focus: new Color(baseLightGrey).fade(0.6).rgb().string()
  },
  noBorderColor: true
}

export const dark: IIntent = {
  textColor: {
    default: baseWhite,
    disabled: baseDarkGrey
  },
  backgroundColor: {
    default: black.shades[2],
    hover: black.shades[1],
    focus: black.shades[2]
  }
}

export const grey: IIntent = {
  textColor: {
    default: baseBlack,
    disabled: baseDarkGrey
  },
  backgroundColor: {
    default: lightGrey.shades[0],
    hover: lightGrey.shades[1],
    focus: lightGrey.shades[2],
    disabled: lightGrey.shades[0]
  }
}

export const intents: {
  [key in TIntents]: IIntent
} = {
  primary,
  secondary,
  ghost,
  ghostInverted,
  dark,
  base,
  grey,
  white
}

export const contentGradientPoints = [
  new Color(baseBlack).fade(1).rgb().string(),
  new Color(baseBlack).fade(0.8).rgb().string(),
  new Color(baseBlack).fade(0.5).rgb().string(),
  new Color(baseBlack).fade(0.3).rgb().string(),
  new Color(baseBlack).fade(0.2).rgb().string()
]
