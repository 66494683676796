import {
  cloneElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled from 'styled-components'

import { IChildrenElementProps } from '~typings/props'

// Slideshow

export interface ISlideshowProps {
  /** Whether or not the slideshow should start on its own */
  autoplay?: boolean
  /** Slides as children */
  children: ReactElement<ISlideProps>[]
  /** Duration of each slide (can be overwritten per slide) */
  duration?: number
}

const SlideshowRenderer = styled.div``

export const Slideshow = ({
  children,
  autoplay = false,
  duration = 5000
}: ISlideshowProps) => {
  const [activeSlide, setActiveSlide] = useState<string | number | undefined>(
    undefined
  )

  const slides = useMemo(() => {
    return children
      .map(
        (child) =>
          ({
            slideKey: child?.props?.slideKey,
            duration: child?.props?.duration || duration,
            defaultActive: child?.props?.defaultActive || false
          } || null)
      )
      .filter((item) => item)
  }, [children, duration])

  // Handle switch to next slide

  const nextSlide = useCallback(() => {
    const currentSlideIndex =
      slides.findIndex((slide) => slide.slideKey === activeSlide) || 0

    let targetSlideIndex = currentSlideIndex + 1

    if (targetSlideIndex >= slides.length) targetSlideIndex = 0

    setActiveSlide(slides[targetSlideIndex].slideKey)
  }, [slides, activeSlide])

  // Activate the first slide

  useEffect(() => {
    const firstActiveSlide =
      slides.find((slide) => slide.defaultActive) || slides[0] || null

    setActiveSlide(firstActiveSlide.slideKey)
  }, [children, autoplay, slides])

  // Handle automatic expiry

  useEffect(() => {
    if (typeof activeSlide === 'undefined' || !autoplay) return () => undefined

    const timeout = setTimeout(
      () => {
        nextSlide()
      },
      slides.find((slide) => {
        return slide.slideKey === activeSlide
      })?.duration || duration
    )

    return () => {
      clearTimeout(timeout)
    }
  }, [activeSlide])

  return (
    <SlideshowRenderer>
      {activeSlide}
      {children.map((child) => {
        return cloneElement(child, {
          active: activeSlide === child.props.slideKey
        })
      })}
    </SlideshowRenderer>
  )
}

// Slide

export interface ISlideProps extends IChildrenElementProps {
  /** Unique identifier for the slide */
  slideKey: string | number
  /** Whether or not the slide is active */
  active?: boolean
  /** Whether or not the slide is active by default */
  defaultActive?: boolean
  /** Duration of the slide, in ms */
  duration?: number
}

export const Slide = ({ children, active = false }: ISlideProps) => {
  return (
    <div>
      {active ? 'active' : 'not active'}
      {children}
    </div>
  )
}
