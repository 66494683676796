import React from 'react'
import styled from 'styled-components'

import { IWithAsElementProps } from '~typings/props'

interface IMediaPropIsBackground {
  /** Display as background image (flexible sizing). Must be used
   * inside a relative or absolute positioned element. */
  asBackground?: boolean
}

export interface IMediaContainerProps extends IMediaPropIsBackground {
  /** Specific aspect ratio */
  aspectRatio?: number
}

export interface IMediaRendererProps
  extends IMediaContainerProps,
    IWithAsElementProps<IMediaContainerProps> {}

export interface IMediaProps extends IMediaContainerProps {
  /** Source of the media */
  src: string
  /** Media type */
  type: 'image' | 'video'
  /** Alternate text */
  alt?: string
  /** Disable lazy-loading */
  disableLazyLoading?: boolean
}

export const MediaContainer = styled.div<IMediaContainerProps>`
  display: block;
  position: relative;

  ${({ aspectRatio }) =>
    aspectRatio
      ? `
      &:after {
        content: '';
        display: block;
        height: 0;
        padding-bottom: ${(1 / aspectRatio) * 100}%;
      }
  `
      : ''}

  ${({ asBackground, aspectRatio }) =>
    asBackground && !aspectRatio
      ? `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;`
      : ''}

  img {
    display: block;
    max-width: 100%;

    ${({ asBackground, aspectRatio }) =>
      asBackground || aspectRatio
        ? `
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;`
        : 'position: relative;'}
  }
`

const srcSetBreakpoints = [320, 640, 1280, 2560, 3640, 4720]

export const generateSrcSet: (src: string, type?: string) => string = (
  src: string,
  type
) => {
  return srcSetBreakpoints
    .map(
      (breakpoint) =>
        `https://images.weserv.nl/?url=${encodeURIComponent(
          src
        )}&w=${breakpoint}&we${type ? `&output=${type}` : ''} ${breakpoint}w`
    )
    .join(',')
}

/**
 * Renders a media element (image, video, ...)
 */
export const Media = ({
  src,
  type,
  asBackground = false,
  alt,
  aspectRatio,
  disableLazyLoading,
  ...props
}: IMediaProps) => {
  /* eslint-disable jsx-a11y/alt-text */
  return (
    <MediaContainer aspectRatio={aspectRatio} asBackground={asBackground}>
      <picture>
        <source srcSet={generateSrcSet(src, 'webp')} type="image/webp" />
        <img
          loading={disableLazyLoading ? 'eager' : 'lazy'}
          src={src}
          alt={alt || ''}
          role={alt ? undefined : 'presentation'}
          {...props}
        />
      </picture>
      {type === 'video' ? 'Videos are not supported yet' : null}
    </MediaContainer>
  )
}
