import { Helmet, useI18next } from "gatsby-plugin-react-i18next";
import { GlobalStyles, SegmentTrackingProvider } from "@lleed-partners/factor";
import { ReactNode } from "react";
import { LocalMDXProvider } from "../fragments/LocalMDXProvider";

import "gatsby-remark-vscode/styles.css";
import "./global.css";

export const Layout = ({ children }: { children: ReactNode }) => {
  const { t } = useI18next();

  return (
    <SegmentTrackingProvider
      trackingEnabled
      writeKey="InCjm6mDTGYLTOHDADincbgSkcPrtHRO"
      translations={{
        cookies: t("Cookies"),
        disclaimer: t(
          "We use anonymous cookies to improve your experience on our website. We do not use your data for advertising or marketing purposes."
        ),
        customize: t("Customize"),
        accept: t("Accept fair cookies"),
        saveChoices: t("Save choices"),
        cancel: t("Cancel"),
        enable: t("Enable"),
        integrations: {
          "Google Tag Manager": {
            name: "Google Tag Manager",
            description: t(
              "We use Google Tag Manager to analyze popular pages and common user interactions on our website."
            ),
          },
        },
      }}
    >
      <Helmet
        titleTemplate="%s | Lleed & Partners"
        defaultTitle="Lleed & Partners"
      >
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1"
        />
        <meta
          name="google-site-verification"
          content="WFTVI7DhrLJS7gbNo64yeulKhemHRvz9Fovcx3nKczQ"
        />
        <meta charSet="utf-8" />
        <meta property="fb:app_id" content="1256809424786217" />
      </Helmet>
      <GlobalStyles />
      <LocalMDXProvider>{children}</LocalMDXProvider>
    </SegmentTrackingProvider>
  );
};

export default Layout;
