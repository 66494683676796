import { MDXProvider } from "@mdx-js/react";
import { Link as GatsbyLink, useI18next } from "gatsby-plugin-react-i18next";
import { Link, Icon } from "@lleed-partners/factor";
import { Launch32, Scale16 } from "@carbon/icons-react";
import { ReactNode } from "react";
import { Obfuscate } from "react-obfuscate";

interface AProps {
  href: string;
  children?: ReactNode;
}

const A = ({ href, children }: AProps) => {
  const { t } = useI18next();

  let isLocal = href.indexOf("://") < 0 && href.substring(0, 2) !== "//";

  let type = isLocal ? "local" : "external";

  if (href.substring(0, 7) === "mailto:") type = "mail";

  let linkProps = {};

  switch (type) {
    case "local":
      linkProps = {
        as: GatsbyLink,
        to: href,
      };
      break;
    default:
      linkProps = {
        as: "a",
        href: href,
        icon: (
          <Icon
            style={{
              transform: "scale(.7)",
              position: "relative",
              left: "-.2em",
              top: ".05em",
            }}
            icon={<Launch32 />}
          />
        ),
        title: "(" + t("External") + ")",
        target: "_blank",
        rel: "noopener",
      };
      break;
  }

  return (
    <Link {...linkProps}>
      <span>{children || href}</span>
    </Link>
  );
};

const components = {
  a: A,
};

export const LocalMDXProvider = ({ children }: { children: any }) => (
  <MDXProvider components={components}>{children}</MDXProvider>
);
