import { useState } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'

import { NavContext } from '~components/UI/Nav/shared/Context'
import { coloredBackgroundComponent } from '~fragments/color'
import { useIsMobileBreakpoint, useReducedMotion } from '~fragments/hooks'
import { sizedComponent } from '~fragments/size'
import { IChildrenElementProps } from '~typings/props'
import { TIntents } from '~variables/colors'
import { navSizes, unit } from '~variables/sizes'

export * from './contents'

interface INavMainLinkDropdownRendererProps {
  /** Intent */
  intent: TIntents
  /** Whether or not the dropdown is open */
  open?: boolean
}

const NavMainLinkDropdownWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;

  @media (min-width: ${navSizes.breakpoint * unit}rem) {
    z-index: -1;
  }

  @media (max-width: ${navSizes.breakpoint * unit}rem) {
    position: relative;
  }
`

const NavMainLinkDropdownRenderer = styled(
  animated.ul
)<INavMainLinkDropdownRendererProps>`
  list-style: none;

  ${coloredBackgroundComponent({
    interactive: false
  })}

  ${sizedComponent({
    padding: {
      top: 1,
      bottom: 0,
      x: 0.5
    }
  })}

  display: block;
  column-width: 20rem;
  /* grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)); */
  width: 100%;
  position: relative;
`

export interface INavMainLinkDropdownProps
  extends Omit<INavMainLinkDropdownRendererProps, 'intent' | 'size'>,
    IChildrenElementProps {
  /** Identifier for ARIA purposes */
  id?: string
  /** Intent */
  intent?: TIntents
}

export const NavMainLinkDropdown = ({
  children,
  open = false,
  intent,
  ...props
}: INavMainLinkDropdownProps) => {
  const [isVisible, setIsVisible] = useState(false)

  const reduceMotion = useReducedMotion()
  const isMobileBreakpoint = useIsMobileBreakpoint()

  const dropdownAnimation = useSpring({
    opacity: open ? 1 : 0,
    top: open ? '0rem' : '-6rem',
    immediate: reduceMotion || isMobileBreakpoint,
    onStart: () => {
      setIsVisible(true)
    },
    onRest: () => {
      if (!open) setIsVisible(false)
    }
  })

  return (
    <NavContext.Consumer>
      {({ intent: contextIntent, ...context }) => {
        return (
          <NavMainLinkDropdownWrapper>
            <NavContext.Provider
              value={{ ...context, intent: intent || contextIntent }}
            >
              <NavMainLinkDropdownRenderer
                open={isVisible}
                aria-hidden={!open}
                intent={intent || contextIntent}
                style={{
                  ...dropdownAnimation,
                  pointerEvents: open ? 'auto' : 'none',
                  display: isVisible ? undefined : 'none'
                }}
                {...props}
              >
                {children}
              </NavMainLinkDropdownRenderer>
            </NavContext.Provider>
          </NavMainLinkDropdownWrapper>
        )
      }}
    </NavContext.Consumer>
  )
}
