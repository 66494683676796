import { Menu32 } from '@carbon/icons-react'
import { ReactElement, useContext, useState } from 'react'
import styled from 'styled-components'

import { Button } from '~components/UI/Button'
import { Icon } from '~components/UI/Icon'
import { NavMainLogo } from '~components/UI/Nav/Main/Logo'
import { NavContext } from '~components/UI/Nav/shared/Context'
import { NavSeparatorVertical } from '~components/UI/Nav/shared/Separator'
import {
  coloredBackgroundComponent,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { ISizedComponentProps } from '~fragments/size'
import { shadowNormal } from '~variables/shadows'
import { navSizes, unit } from '~variables/sizes'

import { IChildrenElementProps } from '../../../../typings/props'

export * from '~components/UI/Nav/Main/Link'

const NavMainWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 200;

  ${coloredBackgroundComponent({
    interactive: false
  })}

  flex-direction: row;
  /* box-shadow: ${shadowNormal}; */

  @media (max-width: ${navSizes.breakpoint * unit}rem) {
    flex-direction: column;
  }
`

const NavMainLeft = styled.div`
  display: flex;
`

const NavMainLinks = styled.ul`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: ${navSizes.breakpoint * unit}rem) {
    flex-direction: column;
  }
`

const NavMainRight = styled.div``

const NavMainMobileToggleButton = styled.div`
  display: none;
  @media (max-width: ${navSizes.breakpoint * unit}rem) {
    display: block;
  }
`

const NavMainContentPanel = styled.div<{ open: boolean }>`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;

  @media (max-width: ${navSizes.breakpoint * unit}rem) {
    flex-direction: column;
    ${({ open }) => (open ? '' : 'display: none;')}
  }
`

const NavMainLinksPadder = styled.div`
  position: relative;
  flex: 1 1 0;
  height: 100%;

  ${coloredBackgroundComponent({
    interactive: false
  })}
`

export interface INavMainProps
  extends IColoredBackgroundComponentProps,
    ISizedComponentProps,
    IChildrenElementProps {
  /** Elements to displat at the top right */
  rightElements?: ReactElement
  /** Props to pass to the navigation logo */
  navLogoProps?: Record<string, unknown>
}

export const NavMain = ({
  size,
  intent = 'dark',
  children,
  rightElements,
  navLogoProps
}: INavMainProps) => {
  const {
    intent: navContextIntent,
    size: navContextSize,
    ...navContext
  } = useContext(NavContext)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <NavContext.Provider
      value={{
        intent: intent || navContextIntent,
        size: size || navContextSize,
        ...navContext
      }}
    >
      <NavMainWrapper intent={intent}>
        <NavMainLeft>
          <NavMainLogo {...navLogoProps} />
          <NavSeparatorVertical />
          <NavMainMobileToggleButton>
            <NavContext.Consumer>
              {({ size, intent }) => (
                <Button
                  alt="Menu toggle"
                  aria-haspopup="menu"
                  as="button"
                  intent={intent}
                  size={size}
                  leftIcon={<Icon icon={<Menu32 />} />}
                  onClick={() => setIsOpen((isOpen) => !isOpen)}
                  onMouseDown={(e: PointerEvent) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                  title={isOpen ? 'Close menu' : 'Open menu'}
                />
              )}
            </NavContext.Consumer>
          </NavMainMobileToggleButton>
        </NavMainLeft>
        <NavMainContentPanel open={isOpen}>
          <NavMainLinks>
            {children}
            <NavContext.Consumer>
              {({ intent }) => <NavMainLinksPadder intent={intent} />}
            </NavContext.Consumer>
          </NavMainLinks>
          <NavMainRight>{rightElements}</NavMainRight>
        </NavMainContentPanel>
      </NavMainWrapper>
    </NavContext.Provider>
  )
}
