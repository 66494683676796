import { LeadSection, Button, Icon, Post, Section, PostContentsTitle, Title, PostContentsContainer, Media, CardGrid, Card, SizeWrapper } from "@lleed-partners/factor";
import { ArrowRight32 } from "@carbon/icons-react";
import * as React from 'react';
export default {
  LeadSection,
  Button,
  Icon,
  Post,
  Section,
  PostContentsTitle,
  Title,
  PostContentsContainer,
  Media,
  CardGrid,
  Card,
  SizeWrapper,
  ArrowRight32,
  React
};