import { ReactNode } from 'react'
import styled from 'styled-components'

import {
  coloredBackgroundComponent,
  contentGradient,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { h0, h1 } from '~fragments/common/typography'
import { ISizedBoxComponentProps, sizedComponent } from '~fragments/size'
import { mobileBreakpoint, unit } from '~variables/sizes'

export interface ILeadSectionProps extends IColoredBackgroundComponentProps {
  /** Title */
  title: string
  /** Subtitle */
  subtitle?: string | Element
  /** Call to action */
  cta?: ReactNode
  /** Background media (`<Media/>` element) */
  backgroundMedia?: ReactNode
  /** Pad the top of the element */
  padTop?: boolean
  /** Inline the element (if inside a page layout) */
  inline?: boolean
  /** Reduce padding */
  reducePadding?: boolean
  /** Category */
  category?: string
}

interface ILeadRendererProps
  extends IColoredBackgroundComponentProps,
    ISizedBoxComponentProps {
  padTop?: boolean
  inline?: boolean
  reducePadding?: boolean
  shadow?: boolean
}

const LeadRenderer = styled.section<ILeadRendererProps>`
  ${({ inline, reducePadding }) =>
    sizedComponent({
      padding: {
        x: 1,
        top: inline && !reducePadding ? 3 : 2,
        bottom: 1
      },
      margin: false
    })}

  @media (max-width: ${mobileBreakpoint * unit}rem) {
    ${({ inline, reducePadding }) =>
      sizedComponent({
        padding: {
          x: 1,
          top: inline && !reducePadding ? 2 : 1,
          bottom: 1
        },
        margin: false
      })}
  }

  ${coloredBackgroundComponent({
    interactive: false
  })}

  ${({ shadow = false }) =>
    shadow
      ? `
      h1, p {
        text-shadow: 0 .1em .4em rgba(0, 0, 0, .35);
      }
  `
      : ''}

  ${({ inline }) => (inline ? '' : 'min-height: 90vh;')}
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${({ padTop }) =>
    padTop
      ? `
    &:before {
      content: '';
      display: block;
      height: 20vh;
    }
  `
      : ''}

  position: relative;
`

const LeadTitleRenderer = styled.h1<{ noMargin?: boolean; inline?: boolean }>`
  ${({ inline }) => (inline ? h1 : h0)}

  ${({ noMargin }) =>
    sizedComponent({
      padding: {
        bottom: noMargin ? 0 : 1.5
      },
      margin: 0
    })}

  ${({ inline }) =>
    inline ? '' : 'letter-spacing: -.025em; line-height: 1.1em;'}

  margin-left: -.1em;

  max-width: 12em;
  position: relative;
`

const LeadContentsRenderer = styled.div`
  ${sizedComponent({
    padding: 0,
    margin: 0
  })}

  position: relative;
`

const LeadBackgroundRenderer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(to left, ${contentGradient});
    width: 100%;
    min-width: 80rem;
  }
`

const LeadSubtitleRenderer = styled.p<{ noMargin?: boolean }>`
  margin: 0;
  font-size: 1.4rem;
  max-width: 25em;

  ${({ noMargin }) =>
    sizedComponent({
      padding: {
        bottom: noMargin ? 0 : 2
      },
      margin: 0
    })}
`

const LeadCategoryRenderer = styled.div`
  & > span {
    display: inline-block;
    ${sizedComponent({
      padding: {
        bottom: 0.5
      },
      margin: {
        bottom: 1
      }
    })}
    border-bottom: 1px solid currentColor;
  }
  font-size: 1.2em;
  opacity: 0.5;
`

export const LeadSection = ({
  title = 'This is a lead block',
  subtitle,
  cta,
  intent = 'base',
  backgroundMedia,
  padTop,
  inline = false,
  category = undefined,
  ...props
}: ILeadSectionProps) => {
  return (
    <LeadRenderer
      role="region"
      intent={backgroundMedia ? 'ghostInverted' : intent}
      shadow={!!backgroundMedia}
      size="xLarge"
      padTop={padTop}
      inline={inline}
      {...props}
    >
      {backgroundMedia && (
        <LeadBackgroundRenderer>{backgroundMedia}</LeadBackgroundRenderer>
      )}
      <LeadContentsRenderer>
        {category && (
          <LeadCategoryRenderer>
            <span>{category}</span>
          </LeadCategoryRenderer>
        )}
        <LeadTitleRenderer noMargin={!cta && !subtitle} inline={inline}>
          {title}
        </LeadTitleRenderer>
        {subtitle && (
          <LeadSubtitleRenderer noMargin={!cta}>
            {subtitle}
          </LeadSubtitleRenderer>
        )}
        {cta || null}
      </LeadContentsRenderer>
    </LeadRenderer>
  )
}
