import { useEffect, useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'

import { useTracking } from '~components/Meta/Tracking'
import {
  coloredBackgroundComponent,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { useOnClickOutside, useReducedMotion } from '~fragments/hooks'
import { sizedComponent } from '~fragments/size'
import {
  IChildrenElementProps,
  IGenericElementProps,
  ITrackableElement
} from '~typings/props'
import { shadowLarge } from '~variables/shadows'

interface IModalRendererProps extends IColoredBackgroundComponentProps {
  /** Disposition of the modal */
  disposition?: 'full' | 'large' | 'small'
  /** Disable inset padding */
  noPadding?: boolean
}

const ModalRenderer = styled(animated.div)<IModalRendererProps>`
  ${({ noPadding = false }) =>
    sizedComponent({
      margin: 4,
      padding: noPadding ? 0 : 2,
      baseline: 'layout'
    })}

  box-shadow: ${shadowLarge};

  ${coloredBackgroundComponent({ interactive: false })}

  ${({ disposition = 'large' }) => {
    switch (disposition) {
      case 'small':
        return `
        margin-left: auto;
        margin-right: auto;
        max-width: 30rem;
      `
      default:
      case 'large':
        return ''
    }
  }}
`

export interface IModalProps
  extends IChildrenElementProps,
    IModalRendererProps,
    ITrackableElement,
    IGenericElementProps {
  /** Whether or not the modal is open */
  open?: boolean
  /** On close listener */
  onClose?: () => void
  /** Standalone modal */
  standalone?: boolean
}

export const Overlay = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`

export const Modal = ({
  intent = 'white',
  open = false,
  children,
  onClose,
  trackId,
  standalone = false,
  ...props
}: IModalProps) => {
  const modalOpenTracker = useTracking({
    type: 'event',
    name: 'modal',
    properties: {
      component: 'factor.modal',
      type: 'open',
      trackId
    }
  })

  const modalCloseTracker = useTracking({
    type: 'event',
    name: 'modal',
    properties: {
      component: 'factor.modal',
      type: 'close',
      trackId
    }
  })

  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      if (!open) return
    }

    if (open) {
      modalOpenTracker()
    } else {
      modalCloseTracker()
    }
  }, [open])

  const [isVisible, setIsVisible] = useState(false)

  const reduceMotion = useReducedMotion()

  const overlayAnimation = useSpring({
    opacity: open ? 1 : 0,
    immediate: reduceMotion,
    onStart: () => {
      setIsVisible(true)
    },
    onRest: () => {
      if (!open) setIsVisible(false)
    }
  })

  const modalAnimation = useSpring({
    opacity: open ? 1 : 0,
    top: open ? '0rem' : '6rem',
    immediate: reduceMotion
  })

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [open])

  const modalContainerRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(modalContainerRef, () => {
    if (onClose) onClose()
  })

  useEffect(() => {
    const eventListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && onClose) {
        onClose()
      }
    }

    document.addEventListener('keyup', eventListener)

    return () => {
      document.removeEventListener('keyup', eventListener)
    }
  })

  return (
    <>
      {isVisible ? (
        <Overlay
          style={{
            ...overlayAnimation,
            pointerEvents: open ? 'unset' : 'none'
          }}
        >
          {standalone ? (
            children
          ) : (
            <ModalRenderer
              intent={intent}
              ref={modalContainerRef}
              style={{
                ...modalAnimation,
                position: 'relative'
              }}
              {...props}
            >
              {children}
            </ModalRenderer>
          )}
        </Overlay>
      ) : null}
    </>
  )
}
