import { createContext, useCallback, useContext } from 'react'

export interface ITrackingProviderProps {
  trackingEnabled?: boolean
}

export interface ITrackingEvent {
  type: 'page' | 'event'
  name: string
  properties?: Record<string, unknown>
}

export interface ITrackingContext extends ITrackingProviderProps {
  track?: (event: ITrackingEvent) => void
}

export const defaultTrackingContext: ITrackingContext = {
  trackingEnabled: false
}

export const TrackingContext = createContext<ITrackingContext>(
  defaultTrackingContext
)

export const useTracking = (event: ITrackingEvent) => {
  const { trackingEnabled, track } = useContext(TrackingContext)

  const trackingCallback = useCallback(() => {
    if (trackingEnabled && track) {
      track(event)
    }
  }, [trackingEnabled, track, event])

  return trackingCallback
}
