import { cloneElement, ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

import {
  coloredBackgroundComponent,
  contentGradient,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { sizedComponent } from '~fragments/size'
import { IChildrenElementProps } from '~typings/props'
import { mobileBreakpoint, unit } from '~variables/sizes'

interface IInline {
  /** Inline the title with the component */
  inline?: boolean
}

interface IFullScreen {
  /** Full screen */
  fullScreen?: boolean
}

export interface ISectionProps
  extends IColoredBackgroundComponentProps,
    IChildrenElementProps,
    IFullScreen {
  /** Title */
  title?: ReactElement
  /** Display title and children inline */
  inline?: boolean
  /** Background media (`<Media/>` element) */
  backgroundMedia?: ReactNode
}

const SectionRenderer = styled.section<
  IInline & IColoredBackgroundComponentProps & IFullScreen
>`
  position: relative;

  ${({ fullScreen }) =>
    sizedComponent({ margin: 0, padding: fullScreen ? 0 : 1 })}

  ${({ inline }) => (inline ? 'display: flex;' : 'display: block;')}
  flex-direction: row;
  flex-wrap: wrap;
  ${coloredBackgroundComponent({})}
`

const SectionTitleRenderer = styled.div<IFullScreen>`
  flex: min(100%, 25rem) 0 1;
  position: relative;

  ${({ fullScreen }) =>
    sizedComponent({
      margin: 0,
      padding: fullScreen ? 2 : 1
    })}
`

const SectionContentsRenderer = styled.div<IFullScreen>`
  position: relative;
  flex: min(40rem, 100%) 1 0;

  ${({ fullScreen }) =>
    sizedComponent({
      margin: 0,
      padding: fullScreen ? 0 : 1
    })}

  @media(max-width: ${mobileBreakpoint * unit}rem) {
    padding: 0;
  }
`

const SectionBackgroundRenderer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(to left, ${contentGradient});
    width: 100%;
    min-width: 80rem;
  }
`

export const Section = ({
  intent,
  title,
  children,
  inline = false,
  backgroundMedia,
  fullScreen = false,
  ...props
}: ISectionProps) => {
  return (
    <SectionRenderer
      role="region"
      inline={inline}
      intent={intent || (backgroundMedia ? 'ghostInverted' : 'base')}
      {...props}
      fullScreen={fullScreen}
    >
      {backgroundMedia && (
        <SectionBackgroundRenderer>{backgroundMedia}</SectionBackgroundRenderer>
      )}
      {title && (
        <SectionTitleRenderer fullScreen={fullScreen}>
          {cloneElement(title, { inline })}
        </SectionTitleRenderer>
      )}
      <SectionContentsRenderer fullScreen={fullScreen}>
        {children}
      </SectionContentsRenderer>
    </SectionRenderer>
  )
}
