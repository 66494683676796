import { css } from 'styled-components'

import { IGenericElementProps } from '~typings/props'

export interface IDisableComponentProps extends IGenericElementProps {
  /** Disables the component */
  disabled?: boolean
}

export const disableComponent = css`
  ${({ disabled }: IDisableComponentProps) =>
    disabled
      ? `
    cursor: not-allowed;
  `
      : ''}
`
