import styled from 'styled-components'

import { useTracking } from '~components/Meta/Tracking'
import { IDisableComponentProps } from '~fragments/disabled'
import { noUserSelect, outlineFocusRing } from '~fragments/interaction'
import {
  IChildrenElementProps,
  IGenericElementProps,
  ITrackableElement,
  IWithAsElementProps,
  TIconType
} from '~typings/props'
import { baseDarkGrey, baseWhite, blue } from '~variables/colors'

interface ILinkRendererProps extends IDisableComponentProps {
  /** Display the link in inverted colors */
  inverted?: boolean
}

const LinkRenderer = styled.span<ILinkRendererProps>`
  ${noUserSelect}
  ${outlineFocusRing}

  text-decoration: none;
  color: ${({ disabled = false, inverted }) => {
    if (disabled) return baseDarkGrey
    return inverted ? baseWhite : blue.shades[0]
  }};

  ${({ disabled = false }) =>
    disabled
      ? ''
      : `

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }`}
`

const LinkIconRenderer = styled.span`
  margin-left: 0.5em;
`

export interface ILinkProps
  extends IWithAsElementProps<unknown>,
    IChildrenElementProps,
    ILinkRendererProps,
    ITrackableElement,
    IGenericElementProps {
  /** Icon to display next to the link */
  icon?: TIconType
}

export const Link = ({ children, icon, trackId, ...props }: ILinkProps) => {
  const trackingClickHandler = useTracking({
    type: 'event',
    name: 'click',
    properties: {
      component: 'factor.link',
      trackId
    }
  })

  const clickHandler = () => {
    trackingClickHandler()

    if (props && props.onClick) props.onClick()
  }

  return (
    <LinkRenderer {...props} onClick={clickHandler}>
      {children}
      {icon && <LinkIconRenderer>{icon}</LinkIconRenderer>}
    </LinkRenderer>
  )
}
