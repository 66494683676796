// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-industry-tsx": () => import("./../../../src/layouts/industry.tsx" /* webpackChunkName: "component---src-layouts-industry-tsx" */),
  "component---src-layouts-insight-tsx": () => import("./../../../src/layouts/insight.tsx" /* webpackChunkName: "component---src-layouts-insight-tsx" */),
  "component---src-layouts-service-tsx": () => import("./../../../src/layouts/service.tsx" /* webpackChunkName: "component---src-layouts-service-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-audit-en-tsx": () => import("./../../../src/pages/audit.en.tsx" /* webpackChunkName: "component---src-pages-audit-en-tsx" */),
  "component---src-pages-audit-fr-tsx": () => import("./../../../src/pages/audit.fr.tsx" /* webpackChunkName: "component---src-pages-audit-fr-tsx" */),
  "component---src-pages-brochures-ffa-data-download-tsx": () => import("./../../../src/pages/brochures/ffa-data-download.tsx" /* webpackChunkName: "component---src-pages-brochures-ffa-data-download-tsx" */),
  "component---src-pages-brochures-ffa-data-tsx": () => import("./../../../src/pages/brochures/ffa-data.tsx" /* webpackChunkName: "component---src-pages-brochures-ffa-data-tsx" */),
  "component---src-pages-brochures-metals-sales-download-tsx": () => import("./../../../src/pages/brochures/metals-sales-download.tsx" /* webpackChunkName: "component---src-pages-brochures-metals-sales-download-tsx" */),
  "component---src-pages-brochures-metals-sales-tsx": () => import("./../../../src/pages/brochures/metals-sales.tsx" /* webpackChunkName: "component---src-pages-brochures-metals-sales-tsx" */),
  "component---src-pages-contact-business-tsx": () => import("./../../../src/pages/contact/business.tsx" /* webpackChunkName: "component---src-pages-contact-business-tsx" */),
  "component---src-pages-contact-general-tsx": () => import("./../../../src/pages/contact/general.tsx" /* webpackChunkName: "component---src-pages-contact-general-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-support-tsx": () => import("./../../../src/pages/contact/support.tsx" /* webpackChunkName: "component---src-pages-contact-support-tsx" */),
  "component---src-pages-contact-thank-you-tsx": () => import("./../../../src/pages/contact/thank-you.tsx" /* webpackChunkName: "component---src-pages-contact-thank-you-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-tsx": () => import("./../../../src/pages/insights.tsx" /* webpackChunkName: "component---src-pages-insights-tsx" */),
  "component---src-pages-legal-en-mdx": () => import("./../../../src/pages/legal.en.mdx" /* webpackChunkName: "component---src-pages-legal-en-mdx" */),
  "component---src-pages-legal-fr-mdx": () => import("./../../../src/pages/legal.fr.mdx" /* webpackChunkName: "component---src-pages-legal-fr-mdx" */),
  "component---src-pages-legal-privacy-en-mdx": () => import("./../../../src/pages/legal/privacy.en.mdx" /* webpackChunkName: "component---src-pages-legal-privacy-en-mdx" */),
  "component---src-pages-legal-privacy-fr-mdx": () => import("./../../../src/pages/legal/privacy.fr.mdx" /* webpackChunkName: "component---src-pages-legal-privacy-fr-mdx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */)
}

