import { ReactNode } from 'react'
import styled from 'styled-components'

import { CardContext } from '~components/UI/Card'
import { IColoredBackgroundComponentProps } from '~fragments/color'
import { intents } from '~variables/colors'

import { ISizedBoxComponentProps } from '../../../fragments/size'

interface ICardListContentsRendererProps
  extends IColoredBackgroundComponentProps,
    ISizedBoxComponentProps {
  /** Bordered */
  bordered?: boolean
}

const CardListContentsRenderer = styled.div<ICardListContentsRendererProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 1px;

  ${({ bordered, intent = 'white' }) => {
    return bordered
      ? `
    overflow: hidden;

    & > *:not(:last-child) {
      outline: 1px solid ${intents[intent].backgroundColor.hover};
    }
  `
      : ''
  }}
`

export interface ICardListProps extends ICardListContentsRendererProps {
  /** Card elements (Use `<Card/>`) */
  children: ReactNode
}

export const CardList = ({
  children,
  bordered = false,
  ...props
}: ICardListProps) => {
  return (
    <CardListContentsRenderer bordered={bordered} {...props}>
      <CardContext.Provider
        value={{
          size: props.size,
          intent: props.intent
        }}
      >
        {children}
      </CardContext.Provider>
    </CardListContentsRenderer>
  )
}
