import { ArrowRight32 } from '@carbon/icons-react'
import styled from 'styled-components'

import { useTracking } from '~components/Meta/Tracking'
import { Icon } from '~components/UI/Icon'
import { NavContext } from '~components/UI/Nav/shared/Context'
import {
  coloredBackgroundComponent,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { disableComponent, IDisableComponentProps } from '~fragments/disabled'
import { noUserSelect, pointerInteractive } from '~fragments/interaction'
import { sizedComponent } from '~fragments/size'
import {
  IChildrenElementProps,
  IGenericElementProps,
  ITrackableElement,
  IWithAsElementProps,
  TIconType
} from '~typings/props'

interface INavMainLinkDropdownCategoryRenderer
  extends IChildrenElementProps,
    IDisableComponentProps {}

const NavMainLinkDropdownCategoryRenderer = styled.li<INavMainLinkDropdownCategoryRenderer>`
  text-decoration: none;
  ${disableComponent}
  ${sizedComponent({
    padding: {
      bottom: 1
    }
  })}
  overflow: hidden; /* fix for Firefox */
  break-inside: avoid-column;
  -webkit-column-break-inside: avoid;
`

const NavMainLinkDropdownEntryRenderer = styled.span<
  IColoredBackgroundComponentProps & IDisableComponentProps
>`
  display: block;
  ${noUserSelect}
  ${pointerInteractive}
  ${coloredBackgroundComponent({ interactive: true })}
  ${sizedComponent({
    padding: {
      y: 0.5,
      x: 1
    },
    border: true,
    baseline: 'font'
  })}
`

const NavMainLinkDropdownCategoryTitleRenderer = styled(
  NavMainLinkDropdownEntryRenderer
)`
  font-weight: bold;
`

const NavMainLinkDropdownCategoryTitleIconRenderer = styled.span`
  margin-left: 0.5em;
`

const NavMainLinkDropdownCategoryLinksRenderer = styled.ul`
  list-style: none;
  padding: 0;
`

export interface INavMainLinkDropdownCategoryProps
  extends INavMainLinkDropdownCategoryRenderer {
  /** Icon for the category */
  icon?: TIconType
  /** Title of the category */
  title?: string
}

export const NavMainLinkDropdownCategory = ({
  children,
  title,
  ...props
}: INavMainLinkDropdownCategoryProps) => {
  return (
    <NavMainLinkDropdownCategoryRenderer>
      <NavContext.Consumer>
        {({ intent }) => {
          return (
            <>
              {title && (
                <NavMainLinkDropdownCategoryTitleRenderer
                  intent={intent}
                  {...props}
                >
                  {title}
                  <NavMainLinkDropdownCategoryTitleIconRenderer>
                    <Icon icon={<ArrowRight32 />} />
                  </NavMainLinkDropdownCategoryTitleIconRenderer>
                </NavMainLinkDropdownCategoryTitleRenderer>
              )}
              <NavMainLinkDropdownCategoryLinksRenderer>
                {children}
              </NavMainLinkDropdownCategoryLinksRenderer>
            </>
          )
        }}
      </NavContext.Consumer>
    </NavMainLinkDropdownCategoryRenderer>
  )
}

type INavMainLinkDropdownLinkRendererProps = IChildrenElementProps

const NavMainLinkDropdownLinkLiRenderer = styled.li``

const NavMainLinkDropdownLinkRenderer = styled(
  NavMainLinkDropdownEntryRenderer
)``

interface INavMainLinkDropdownLinkProps
  extends INavMainLinkDropdownLinkRendererProps,
    IWithAsElementProps<INavMainLinkDropdownLinkRendererProps>,
    ITrackableElement,
    IGenericElementProps {}

export const NavMainLinkDropdownLink = ({
  children,
  trackId,
  ...props
}: INavMainLinkDropdownLinkProps) => {
  const trackingClickHandler = useTracking({
    type: 'event',
    name: 'click',
    properties: {
      component: 'factor.nav-dropdown-link',
      trackId
    }
  })

  const clickHandler = () => {
    trackingClickHandler()

    if (props && props.onClick) props.onClick()
  }

  return (
    <NavMainLinkDropdownLinkLiRenderer>
      <NavContext.Consumer>
        {({ intent }) => {
          return (
            <NavMainLinkDropdownLinkRenderer
              intent={intent}
              {...props}
              onClick={clickHandler}
            >
              {children}
            </NavMainLinkDropdownLinkRenderer>
          )
        }}
      </NavContext.Consumer>
    </NavMainLinkDropdownLinkLiRenderer>
  )
}
