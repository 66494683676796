import { RefObject, useCallback, useEffect, useState } from 'react'

import { navSizes, unit } from '~variables/sizes'

export const useOnLinkClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void
) => {
  useEffect(() => {
    const effectHandler = (event: MouseEvent) => {
      if (event.target) {
        const target = event.target as HTMLElement
        if (target.closest('a')) {
          callback()
        }
      }
    }

    if (ref.current) ref.current.addEventListener('mouseup', effectHandler)

    return () => {
      if (ref.current) ref.current.removeEventListener('mouseup', effectHandler)
    }
  }, [ref])
}

export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  callback: () => void
) => {
  useEffect(() => {
    const effectHandler = (event: MouseEvent) => {
      if (
        ref.current &&
        event.target instanceof Element &&
        !ref.current.contains(event.target)
      ) {
        callback()
      }
    }

    document.addEventListener('mouseup', effectHandler)

    return () => {
      document.removeEventListener('mouseup', effectHandler)
    }
  }, [ref, callback])
}

const useMediaQueryResult = (mediaQuery: MediaQueryList | false): boolean => {
  const [mediaQueryResult, setMediaQueryResult] = useState<boolean>(false)

  const eventListener = useCallback(() => {
    if (!mediaQuery || mediaQuery.matches) {
      setMediaQueryResult(true)
    } else {
      setMediaQueryResult(false)
    }
  }, [])

  useEffect(() => {
    if (!mediaQuery || mediaQuery.matches) {
      setMediaQueryResult(true)
    } else {
      setMediaQueryResult(false)
    }

    if (mediaQuery) mediaQuery.addEventListener('change', eventListener)

    return () => {
      if (mediaQuery) mediaQuery.removeEventListener('change', eventListener)
    }
  }, [])

  return mediaQueryResult
}

const reducedMotionMediaQuery =
  typeof window !== 'undefined' &&
  window &&
  window.matchMedia('(prefers-reduced-motion: reduce)')

export const useReducedMotion = (): boolean => {
  const reduceMotion = useMediaQueryResult(reducedMotionMediaQuery)

  return reduceMotion
}

const mobileBreakpointMediaQuery =
  typeof window !== 'undefined' &&
  window &&
  window.matchMedia(`(max-width: ${navSizes.breakpoint * unit}rem)`)

export const useIsMobileBreakpoint = () => {
  const isMobileBreakpoint = useMediaQueryResult(mobileBreakpointMediaQuery)

  return isMobileBreakpoint
}
