import { createContext } from 'react'

import { TIntents } from '~variables/colors'

interface INavContext {
  intent: TIntents
}

export const defaultNavContext: INavContext = {
  intent: 'dark'
}

export const NavContext = createContext<INavContext>(defaultNavContext)

export default NavContext
