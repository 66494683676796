import styled from 'styled-components'

import { sizedComponent } from '~fragments/size'

export const SitemapRenderer = styled.ul`
  list-style: none;
  ${sizedComponent({
    baseline: 'font',
    padding: 2
  })}
`

export const SitemapEntryRenderer = styled.li<{
  isCategory?: boolean
}>`
  display: block;

  ${({ isCategory = false }) =>
    sizedComponent({
      baseline: 'font',
      padding: {
        x: 0,
        top: isCategory ? 1 : 0.5,
        bottom: 0.5
      }
    })}

  ${({ isCategory = false }) => {
    return isCategory
      ? `
      font-weight: bold;
    `
      : ''
  }}
`
