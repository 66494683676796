import { createContext } from 'react'

import { TIntents } from '~variables/colors'

export interface IFooterContext {
  intent: TIntents
}

export const FooterContext = createContext<IFooterContext>({
  intent: 'secondary'
})
