import { createContext, ReactNode, useContext } from 'react'
import styled from 'styled-components'

import {
  coloredBackgroundComponent,
  IColoredBackgroundComponentProps
} from '~fragments/color'
import { disableComponent, IDisableComponentProps } from '~fragments/disabled'
import { noUserSelect, pointerInteractive } from '~fragments/interaction'
import { ISizedComponentProps, sizedComponent } from '~fragments/size'
import {
  IChildrenElementProps,
  ITrackableElement,
  IWithAsElementProps,
  TIconType
} from '~typings/props'
import { TIntents } from '~variables/colors'
import { focusRingSize, TSizes } from '~variables/sizes'

import { useTracking } from '../../Meta/Tracking'

export const defaultCardContext: {
  intent?: TIntents
  size?: TSizes
} = {
  intent: undefined,
  size: undefined
}

export const CardContext = createContext(defaultCardContext)

export interface ICardRendererProps
  extends IColoredBackgroundComponentProps,
    ISizedComponentProps,
    IDisableComponentProps {
  /** Whether or not the card itself is interactive */
  interactive?: boolean
}

const CardRenderer = styled.div<ICardRendererProps>`
  display: flex;
  flex-direction: column;
  ${noUserSelect}
  ${({ interactive = false }) => coloredBackgroundComponent({ interactive })}
  ${({ interactive = false }) => interactive && pointerInteractive}
  ${disableComponent}
  ${sizedComponent({
    border: true,
    padding: 0
  })}
`

const CardIconRenderer = styled.span`
  display: block;
  ${sizedComponent({
    padding: {
      bottom: 2,
      top: 0.25
    }
  })}
  font-size: 1.5em;
`

const CardTitleRenderer = styled.h3`
  display: block;
  font-size: 1.25em;
  ${sizedComponent({ padding: { bottom: 1 } })}
  max-width: 25em;
`

const CardContentsRenderer = styled.span`
  display: block;
  max-width: 25em;
  opacity: 0.7;
`

const CardCTARenderer = styled.span`
  display: block;
  ${sizedComponent({ padding: { top: 2 } })}
`

const CardContentWrapper = styled.span`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  ${sizedComponent({
    padding: 1
  })}
`

const Spacer = styled.span`
  display: block;
  flex: 1 0 0;
`

const CardMediaRenderer = styled.span`
  display: block;
  margin: -${focusRingSize}px;
`

export interface ICardProps
  extends IChildrenElementProps,
    ICardRendererProps,
    IWithAsElementProps<ICardRendererProps>,
    ITrackableElement {
  /** Card icon */
  icon?: TIconType
  /** Card title */
  title?: ReactNode
  /** Call to action */
  cta?: ReactNode
  /** Media (use `<Media/>`), it is best to use a fixed aspect ratio
   * for media elements in cards. */
  media?: ReactNode
}

export const Card = ({
  icon = false,
  title = false,
  cta = false,
  intent,
  media = false,
  size,
  children,
  trackId,
  ...props
}: ICardProps) => {
  const context = useContext(CardContext)

  const trackingClickHandler = useTracking({
    type: 'event',
    name: 'click',
    properties: {
      component: 'factor.card',
      trackId
    }
  })

  const clickHandler = () => {
    if (props.interactive) {
      trackingClickHandler()
    }

    if (props && props.onClick) props.onClick()
  }

  return (
    <CardRenderer
      role="group"
      intent={intent || context.intent || 'grey'}
      size={size || context.size || 'large'}
      {...props}
      onClick={clickHandler}
    >
      {media && <CardMediaRenderer>{media}</CardMediaRenderer>}
      <CardContentWrapper size={size || context.size || 'large'}>
        {icon && <CardIconRenderer>{icon}</CardIconRenderer>}
        {title && <CardTitleRenderer>{title}</CardTitleRenderer>}
        {children && <CardContentsRenderer>{children}</CardContentsRenderer>}
        <Spacer />
        {cta && <CardCTARenderer role="navigation">{cta}</CardCTARenderer>}
      </CardContentWrapper>
    </CardRenderer>
  )
}
