import { ReactNode } from 'react'
import styled from 'styled-components'

import { CardContext } from '~components/UI/Card'
import { IColoredBackgroundComponentProps } from '~fragments/color'
import { ISizedBoxComponentProps } from '~fragments/size'
import { intents } from '~variables/colors'

interface ICardGridContentsRendererProps
  extends IColoredBackgroundComponentProps,
    ISizedBoxComponentProps {
  /** Bordered */
  bordered?: boolean
}

const CardGridContentsRenderer = styled.div<ICardGridContentsRendererProps>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(18rem, 50%), 1fr));
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  grid-auto-rows: 1fr;

  ${({ bordered, intent = 'white' }) => {
    return bordered
      ? `
    overflow: hidden;

    & > *:not(:last-child) {
      outline: 1px solid ${intents[intent].backgroundColor.hover};
    }
  `
      : ''
  }}
`

export interface ICardGridProps extends ICardGridContentsRendererProps {
  /** Card elements (Use `<Card/>`) */
  children: ReactNode
}

export const CardGrid = ({
  children,
  bordered = false,
  ...props
}: ICardGridProps) => {
  return (
    <CardGridContentsRenderer bordered={bordered} {...props}>
      <CardContext.Provider
        value={{
          size: props.size,
          intent: props.intent
        }}
      >
        {children}
      </CardContext.Provider>
    </CardGridContentsRenderer>
  )
}
